.defaultStyle {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: nowrap;
  column-gap: 4%;
  row-gap: 10px;
}
.defaultStyle label {
  display: flex;
  flex-direction: column;
  color: var(--superDarkGrey);
  width: 48%;
  flex-grow: 1;
}
.defaultStyle input {
  border: 1px solid var(--specialGrey);
  outline-style: none;
  border-radius: 100px;
  box-sizing: border-box;
  margin-top: 8px;
  padding: 0px 5px;
  height: 34px;
  width: 100%;
  font-size: 17px;
  text-indent: 4px;
}
.defaultStyle input:disabled {
  border-style: none;
}

@media screen and (max-width: 676px) {
  .defaultStyle {
    flex-wrap: wrap;
  }
  .defaultStyle label {
    min-width: 200px;
  }
}