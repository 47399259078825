.box {
  width: 80%;
}
.alertBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
.alertImg {
  width: 54px;
  height: 54px;
  margin-bottom: 20px;
}
.text {
  font-weight: 500;
  text-align: center;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.cancel {
  width: 254px;
  height: 43px;
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  cursor: pointer;
}
.cancel:hover {
  background-color: var(--hoverColor);
  color: white;
  transition: .4s;
}

.confirm {
  width: 254px;
  height: 43px;
  background-color: var(--mainColor);
  border-radius: 10px;
  color: white;
  cursor: pointer;
  border: none;
}
.confirm:hover {
  background-color: var(--hoverColor);
  transition: .4s;
}
.text {
  margin: 10px 0px 20px 0px;
  font-size: 18px;
}
.price {
  padding: 10px 40px ;
  font-weight: 500;
  background-color: #e4e4e4;
  border-radius: 10px;
  margin-top: 5px;
  font-size: 18px;
}
@media screen and (min-width:1200px) {
  
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
  
}
@media screen and (max-width:767px) {
  .btnBox {
      flex-direction: column;
      align-items: center;
  }
  .cancel {
      width: 200px;
      height: 35px;
      margin-bottom: 18px;
  }
  .confirm {
    width: 200px;
    height: 35px;
  }
}