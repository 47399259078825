.box_content {
  display: flex;
  box-sizing: border-box;
}
.container {
  box-sizing: border-box;
  background-color: #fff;
  width: 100%;
  padding: 0px 20px 40px 20px;
}
.securityInfoBox {
  margin-top: 30px;
}
.titleBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #f2f2f2;
  padding: 10px 0px;
}
.leftTitle {
  display: flex;
  align-items: center;
}
.title {
  font-size: 20px;
  color: var(--superDarkGrey);
  margin-right: 15px;
}
.securityType {
  margin-right: 15px;
  font-size: 20px;
  color: var(--superDarkGrey);
}
.status {
  font-size: 14px;
  background-color: #EEB6B6;
  padding: 2px 5px;
  border-radius: 3px;
  color: #770B0B;
}
.boxLeft {
  box-sizing: border-box;
  width: 50%;
  border-right: 1px solid var(--lightGrey);
  padding-right: 20px;
}
.boxRight {
  box-sizing: border-box;
  width: 50%;
  padding-left: 20px;
}
.securityTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;
}
.subContent{
  display: flex;
  flex-wrap: wrap;
}
.subContentItem {
  margin-right: 20px;
  margin-bottom: 20px;
}
.itemTitle {
  font-size: 14px;
  margin-bottom: 6px;
}
.item {
  padding: 3px 6px;
  font-size: 14px;
  background-color: #F2F2F2;
  border-radius: 3px;
}
.noteBox {
  margin-bottom: 20px;
}
.leftTextArea {
  margin-top: 6px;
  box-sizing: border-box;
  resize: none;
  width: 100%;
  min-height:180px;
  border: none;
  background-color: #F2F2F2;
  border: 10px;
  padding: 5px;
  outline-color: var(--mainColor);
}
.photoBox {
  display: flex;
  flex-wrap: wrap;
  margin-top: 6px;
}
.photo {
  width: 126px;
  height: 70.875px;
  background-color:#F2F2F2 ;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #B3B3B3;
  font-size: 14px;
  margin-bottom: 10px;
}
.borderBottom {
  border-bottom: 1px solid #f2f2f2;
}
.roleBox {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.roleTitle {
  font-weight: 500;
  color: var(--superDarkGrey);
  margin-right: 20px;
}
.role {
  margin-top: 20px;
}
.select {
  border: none;
  background-color: #f2f2f2;
  padding: 4px 10px;
  padding-right: 40px;
  cursor: pointer;
  -webkit-appearance: none;
  background: url(../../../assets/selectDownBlack.svg) no-repeat right;
  background-color: #F2F2F2;
  border-radius: 5px;
  font-size: 14px;
}
.roleItem {
  font-size: 14px;
}
.textAreaInput {
  margin-top: 20px;
}
.uploadBox {
  margin-top: 20px;
  width: 100%;

}
.uploadInnerBox {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px dashed var(--lightGrey);
  padding: 3px 10px;
  border-radius: 10px;
}
.leftUpload {
  display: flex;
  align-items: center;
}
.uploadImg {
  width: 26px;
}
.dragTitle {
  margin-left: 10px;
  color: #63636B;
}
.deleteBtn {
  background-color: transparent;
  font-size: 14px;
  cursor: pointer;
  border: none;
}
.uploadItem {
  display: flex;
}
.uploadFileBox {
  margin-top: 10px;
}
.uploadFileItem {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2F2F2;
  width: 280px;
  border-radius: 5px;
  padding: 2px 5px;
  margin-bottom: 5px;
}
.uploadFileLeft {
  display: flex;
  align-items: center;
}
.fileName {
  margin-left: 5px;
  color: #3C3A3A;
}
.delBtn {
  width: 20px;
  cursor: pointer;
}
.inputLong {
  margin-top: 30px;
}
.inputLong label {
  font-size: 16px;
}
.inputLong input {
  width: 280px;
  height: 30px;
  border-radius: 5px;
}
.confirmBox {
  box-sizing: border-box;
  width: 100%;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #F2F2F2;
  border-radius: 10px;
  padding-left: 10px;
  color: #3C3A3A;
}
.confirmTitle {
  font-size: 14px;
}
.delBtnBox {
  border: none;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .container {
    margin-right: 20px;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .container {
    margin-top: 10px;
    margin-right: 20px;
  }
  .box_content {
    display: flex;
    flex-direction: column;
  }
  .boxLeft {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--lightGrey);
    padding-right: 0px;
  }
  .boxRight {
    width: 100%;
    padding-left: 0px;
  }
  .title {
    margin-right: 10px;
  }
  .securityType {
    margin-right: 10px;
    color: var(--superDarkGrey);
  }
}
@media screen and (max-width:767px) {
  .container {
    margin-top: 10px;
  }
  .box_content {
    display: flex;
    flex-direction: column;
  }
  .boxLeft {
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--lightGrey);
    padding-right: 0px;
  }
  .boxRight {
    width: 100%;
    padding-left: 0px;
  }
  .title {
    font-size: 16px;
    margin-right: 10px;
  }
  .securityType {
    margin-right: 10px;
    font-size: 16px;
    color: var(--superDarkGrey);
  }
}