body.oceanlakes {
  --mainColor: #2194D2;
  --secondaryColor: #1883bc;
  --thirdColor: #F4AA1D;
  --hoverColor: #126ea0;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#2194D2, #32B7E6);
}
