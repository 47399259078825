.action {
    position: relative;
}
.btn {
    text-align: center;
}
.btnOpen {
    text-align: center;
}
.btn button {
    background-color: #fff;
}
.btnOpen button {
    background-color: var(--mainColor);
    color: white;
    border: none;
}
.stickyTd {
    position: sticky;
    left: 0px;
    top: 0px;
    height: 100%;
    text-align: center;
    border-right: 1px solid var(--lightGrey);
    z-index: 1;
    background-color: #fff;
}
.tdIndexHigh {
    position: sticky;
    left: 0px;
    top: 0px;
    height: 100%;
    text-align: center;
    border-right: 1px solid var(--lightGrey);
    z-index: 2;
    background-color: #fff;
    overflow: visible;
}
.tdIndexLow {
    position: sticky;
    left: 0px;
    top: 0px;
    height: 100%;
    text-align: center;
    border-right: 1px solid var(--lightGrey);
    z-index: 1;
    background-color: #fff;
}