.container {
    position: absolute;
    background-color: #F2F2F2;
    min-height: 100%;
    width: 100%;
    left: "0";
    bottom: "0";
    box-sizing: border-box;
    padding-bottom: 0px;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .container {
        position: absolute;
        background-color: #F2F2F2;
        min-height: 100%;
        width: 100%;
        left: "0";
        bottom: "0";
        box-sizing: border-box;
        padding-bottom: 80px;
    }
}

