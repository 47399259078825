body.carillonbeach {
  --mainColor: #006498;
  --secondaryColor: #02537D;
  --thirdColor: #009F93;
  --hoverColor: #051525;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#009F93, #568eac);
}
