.container {
    position: fixed;
    top: 80px;
    z-index: 10000;
    width: 100%;
}
.button__container {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    flex: 1;
    width: 100%;
    padding: 10px;
    border-radius: 10px;
}
.button {
    padding: 10px;
    border-radius: 5px;
}