.labelList{
    box-sizing: border-box;
    display: flex;
    width: 100%;
    justify-content: space-between;
    border-bottom:1px solid var(--backgroungColor);
    padding: 8px 4px;
    padding-right: 10px;
    cursor: pointer;
}
.labelList li {
    list-style: none;
    font-size: 15px;
    color: var(--superDarkGrey);

}
.labelList:hover{
    background-color: var(--backgroungColor);
}

.checkBoxSmall {
    background-color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    width: 14px;
    height: 14px;
    border: 1px solid #bebdbd;

}
.checkBoxSmall:checked {
    background: url(../../assets/checkedSmall.svg) no-repeat center;
    background-color: #fff;
}
.checkBoxLarge {
    background-color: #fff;
    cursor: pointer;
    -webkit-appearance: none;
    border: 1px solid #929090;
    width: 20px;
    height: 20px;

}
.checkBoxLarge:checked {
    background: url(../../assets/checkedLarge.svg) no-repeat center;
    background-color: #fff;
}
.labelNormal {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.labelNormal li {
    list-style: none;
    font-size: 15px;
    margin-bottom: 4px;
    color: #000;
}