body.pinnacleport {
  --mainColor: #0534B6;
  --secondaryColor: #062d98;
  --thirdColor: #B12019;
  --hoverColor: #062d98 ;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#0537A6, #6EACF4);
}
