.subHeader {
    display: flex;
    align-items: center;
    height: 103px;
    border-radius: 0 20px 0 0;
}
.circle {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    margin-left: 45px;
    margin-right: 17px;
}
.title {
    font-size: 35px;
    font-weight: 500;
}
.form {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 94px;
    padding-bottom: 108px;
}
.innerForm {
    width: 56%;
}
.inputBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}
.inputShort {
    width: 48%;
}
.inputLong {
    margin-bottom: 25px;
}
.alert {
    font-size: 14px;
    text-align: center;
    margin-left: 8px;
}
.alertBox {
    display: flex;
    align-items: center;
    justify-content: center;
}
.saveBtn {
    width: 40%;
}
.btnBox {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 43px;
}
.labelBox {
    display: flex;
    flex-direction: column;
}
.selectBox {
    width: 224px;
    height: 40px;
    border-radius: 20px;
    border: 2px solid var(--lightGrey);
    -webkit-appearance: none;
    -moz-appearance: none;
    outline-style: none;
    text-indent: 10px;
    font-weight: 500;
    background: url(../../../assets/dropDownBtn.svg) no-repeat scroll right center transparent;
    padding-right: 23px;
    cursor: pointer;
    margin-bottom: 25px;
    margin-top: 10px;
}


@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .innerForm {
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .innerForm {
        width: 80%;
    }
    .subHeader {
        border-radius: 0;
    }
    .saveBtn {
        width: 200px;
    }

}
@media screen and (max-width:767px) {
    .circle {
        width: 16px;
        height: 16px;
        margin-left: 20px;
    }
    .title {
        font-size: 20px;
        font-weight: 500;
    }
    .subHeader {
        border-radius: 0;
        height: 60px;
    }
    .form {
        width: 100%;
        margin-top: 30px;
    }
    .innerForm {
        width: 80%;
    }
    .inputBox {
        flex-direction: column;
        margin-bottom: 0px;
    }
    .inputShort {
        width: 100%;
        margin-bottom: 18px;
    }
    .inputLong {
        margin-bottom: 18px;
    }
    .saveBtn {
        width: 200px;
    }
    .selectBox {
        width: 100px;
        height: 30px;
        text-indent: 10px;
    }

}