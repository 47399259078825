body.palmettodunes {
  --mainColor: #224367;
  --secondaryColor: #2b5985;
  --thirdColor: #f9bd2c;
  --hoverColor: #051525;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#224367, #5ad3ed);
}
