/* body.CB {
  --mainColor:#65BAD1;
  --secondaryColor:#2891AE;
  --thirdColor:#D7B872;
  --hoverColor:#0e5b70;
  --darkGrey: #636365;
  --specialGrey:#BDBDBD;
  --sucessColor:#28AE2D;
  --alertColor:#ca2a06;
  --gradient: linear-gradient(#208CA9,#65BAD1);
}
body.palmettodunes {
  --mainColor:#224367;
  --secondaryColor:#2B5985;
  --thirdColor:#F9BD2C;
  --hoverColor:#051525;
  --darkGrey: #636365;
  --specialGrey:#BDBDBD;
  --sucessColor:#28AE2D;
  --alertColor:#ca2a06;
  --gradient: linear-gradient(#224367,#5AD3ED);
} */
* {
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
}
.mainColor {
  background-color: var(--mainColor);
}

.secondaryColor {
  background-color: var(--secondaryColor);
}

.thirdColor {
  background-color: var(--thirdColor);
}
.white {
  background-color: #fff;
}
.black {
  background-color: black;
}
.superDarkGrey {
  background-color: var(--superDarkGrey);
}
.darkGrey {
  background-color: var(--darkGrey);
}

.mediumGrey {
  background-color: var(--mediumGrey);
}

.lightGrey {
  background-color: var(--lightGrey);
}
.specialGrey {
  background-color: var(--specialGrey);
}
.backgroungColor {
  background-color: var(--backgroungColor);
}
.sucessColor {
  background-color: var(--sucessColor);
}
.alertColor {
  background-color: var(--alertColor);
}

.linerGradient {
  background-image: var(--gradient);
}
.green {
  background-color: var(--green);
}
.red {
  background-color: var(--red);
}

.textColorSuperDark {
  color: var(--superDarkGrey);
}
.textColorDark {
  color: var(--darkGrey);
}

.textColorMedium {
  color: var(--mediumGrey);
}
.textColorLight {
  color: var(--lightGrey);
}

.textColorWhite {
  color: #fff;
}

.textColorSecondary {
  color: var(--secondaryColor);
}
.textColorMain {
  color: var(--mainColor);
}
.textColorThird {
  color: var(--thirdColor);
}
.textColorSpecial {
  color: var(--specialGrey);
}
.textColorSucess {
  color: var(--sucessColor);
}
.textColorAlert {
  color: var(--alertColor);
}
.textGreen {
  color: var(--green);
}
.textRed {
  color: var(--red);
}
