body.cb {
  --mainColor: #65bad1;
  --secondaryColor: #2891ae;
  --thirdColor: #d7b872;
  --hoverColor: #0e5b70;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#208ca9, #65bad1);
}
