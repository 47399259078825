.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
  padding-bottom: 200px;
}
.box {
   width: 82%;
    margin-top: 180px;
    border-radius: 20px;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
    overflow: hidden;
}
.securityPart {
  /* height: 392px; */
  display: flex;
  flex-direction: column;
}
.logo {
  filter: brightness(0) invert(1);
  width: 54px;
  height: 64px;
  margin-left: 48px;
}
.subNav {
  flex: 1;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 20px 20px 0 0;
  height: 103px;
  background-color: var(--mainColor);
}
.subTitle {
  font-size: 35px;
  font-weight: 500;
  color: white;
  margin-left: 27px;
}

.boxInfor {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .25);
  border-radius: 0 0 20px 20px;
}
.lstCard {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  overflow: auto;
  padding: 20px 46px 10px 46px;
}
/* .lstCard::after {
  content: '';
  width: 204px;
  margin: 10px;
} */

/* .invisible {
  width: 204px;
  margin: 10px;
} */
.cardBox {
  display: flex;
  justify-content: center;
}
.link {
  display: flex;
}

.card {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  width: 200px;
  height: 180px;
  /* min-width: 204px; */
  /* justify-content: center; */
  padding-top: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .25);
  margin: 10px;
}

.title {
  flex: 0.14;
  font-size: 18px;
  margin: 21px 29px 0 30px;
}

.description {
  font-size: 14px;
  font-weight: 600;
  max-width: 270px;
  margin-top: 0px;
  height: 40px;
  line-height: 150%;
}
.activePart {
  background-color: var(--thirdColor);
}
.activeText {
  color: white;
}
.activeIcon{
  /* set image to white color */
  filter: brightness(0) invert(1);
}
.imgOutBox {
  height: 80px;
  display: flex;
  align-items: center;
}
.imageIcon {
  /* height:50px */
}
@media screen and (min-width:1200px) {
 
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .box {
    width: 98%;
  }
   .subNav {
    border-radius: 0;
    height: 103px;
  }
  .lstCard {
    padding: 20px;
  }
  
  
  .cardBox {
    display: flex;
    justify-content: center;
  }
  .link {
    display: flex;
  }
  
  .card {
    width: 204px;
    height: 120px;
  }
  
  .title {
    flex: 0.14;
    font-size: 18px;
    margin: 21px 29px 0 30px;
  }
  
  .activePart {
    background-color: var(--thirdColor);
  }
  .activeText {
    color: white;
  }
  .activeIcon{
    /* set image to white color */
    filter: brightness(0) invert(1);
  }
  .imgOutBox {
    height: 30px;
  }
  .imageIcon {
    width:50px
  }


}
@media screen and (min-width:768px) and (max-width:991px) {
  .box {
    width: 100%;
    margin-top: 180px;
    border-radius: 0px;
  }
  .subNav {
    border-radius: 0;
    height: 103px;
  }
  .lstCard {
    padding: 20px;
  }
  
  
  .cardBox {
    display: flex;
    justify-content: center;
  }
  .link {
    display: flex;
  }
  
  .card {
    width: 204px;
    height: 120px;
  }
  
  .title {
    flex: 0.14;
    font-size: 18px;
    margin: 21px 29px 0 30px;
  }
  
  .activePart {
    background-color: var(--thirdColor);
  }
  .activeText {
    color: white;
  }
  .activeIcon{
    /* set image to white color */
    filter: brightness(0) invert(1);
  }
  .imgOutBox {
    height: 30px;
  }
  .imageIcon {
    width:50px
  }

}
@media screen and (max-width:767px) {
  .box {
    width: 100%;
    margin-top: 44px;
    border-radius: 0px;
  }
  .subNav {
    border-radius: 0;
    height: 58px;
  }
  .subTitle {
    font-size: 20px;
    font-weight: 500;
    margin-left: 10px;
  }
  .logo {
    width: 40px;
    margin-left: 20px;
  }
  .lstCard {
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 320px;
    flex-wrap: wrap;
  }
  
  .cardBox {
    display: flex;
    width: 50%;
  }
  .link {
    width: 100%;
    display: flex;
  }
  .card {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 20px;
    width: 100%;
    height: 80px;
    /* min-width: 204px; */
    /* justify-content: center; */
    padding-top: 5px;
    box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, .25);
    margin: 5px;
  }
  
  .title {
    flex: 0.14;
    font-size: 13px;
    margin: 0px;
    margin-top: 10px;
  }
  
  .description {
    font-size: 14px;
    font-weight: 600;
    max-width: 270px;
    margin-top: 0px;
    height: 40px;
    line-height: 150%;
  }
  .activePart {
    background-color: var(--thirdColor);
  }
  .activeText {
    color: white;
  }
  .activeIcon{
    /* set image to white color */
    filter: brightness(0) invert(1);
  }
  .imgOutBox {
    height: 40px;
    display: flex;
    align-items: center;
  }
  .imageIcon {
    width:30px
  }
  .invisible {
  display: none;
  }
}

