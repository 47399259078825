.box__mainTitleRight {
  flex: 0.15;
  max-width: 424px;
  font-size: 24px;
  font-weight: 500;
  margin-bottom: 51px;
  margin-left: 30%;
}

.box__subTitle {
  display: flex;
  flex: 0.05;
  max-width: 297px;
  margin-bottom: 20px;
  margin-left: 30%;
}
.box__inputLong {
  display: flex;
  flex: 0.1;
  margin-bottom: 180px;
  margin-left: 30%;
}
.box__inputField {
  flex: 0.7;
  max-width: 337px;
  min-width: 200px;
}
.box__btnRight {
  display: flex;
  flex: 0.1;
  justify-content: flex-end;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 30%;
  max-width: 337px;
}
.emailBox {
  display: flex;
  font-size: 14px;
  margin-top: 10px;
}
.box__btnRightInnerBox {
  margin-left: 10px;
  margin-top: 10px;
}
.alert {
  display: flex;
  margin-left: 30%;
}
.alertBox {
  flex: 1;
  max-width: 337px;
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box__inputField {
    flex: 1;
  }
}
@media screen and (max-width: 767px) {
  .box__mainTitleRight {
    margin-left: 20px;
  }
  .box__subTitle {
    margin-left: 20px;
  }
  .box__inputLong {
    margin-left: 20px;
  }
  .box__btnRight {
    justify-content: flex-start;
    margin-left: 20px;
  }
  .allert {
    margin-left: 20px;
    margin-bottom: 10px;
    font-weight: 500;
  }
}
