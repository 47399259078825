 .violationIncidentBox {
   background-color: white;
   padding: 70px;
 }

 .inputLong {
   margin-bottom: 0px;
   width: 49%;
 }

 .inputLong input {
  height: 30px;
 }

 .labelBox {
   display: flex;
   flex-direction: column;
   font-size: 16px;
 }

 .select {
   box-sizing: border-box;
   border-radius: 100px;
   border: 1px solid var(--specialGrey);
   height: 30px;
   overflow: hidden;
   width: 100%;
   outline-style: none;
   margin-top: 8px;
   padding-left: 5px;
   padding-right: 5px;
   font-size: 16px;
   text-indent: 5px;
   -webkit-appearance: none;
   -moz-appearance: none;
   background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
   cursor: pointer;
 }

 .title {
  font-size: 24px;
  color: var(--superDarkGrey);
  margin-bottom: 20px;
 }

 .inputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
 }


 .mainInfor {
   display: flex;
   /* padding-top: 50px; */
 }

 .firstInfor {
  width: 50%;
  padding: 0px 40px 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-right: 1px solid  var(--lightGrey);
 }
 .noteTitle {
  font-size: 15px;
  margin-top: 10px;
  color:var(--superDarkGrey);
  font-weight: 500;
 }

 .secondInfor {
  width: 50%;
  padding: 0px 0px 20px 40px ;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
 }
 .or {
  font-size: 18px;
  font-weight: 700;
  color: var(--mainColor);
  display: flex;
  justify-content: center;
  margin: 40px 0px 20px 0px;
}

 .inputsContent {
   display: flex;
   flex-direction: column;
 }

 .textArea {
  box-sizing: border-box;
   width: 100%;
   height: 231px;
   border: none;
   border: 2px solid var(--lightGrey);
   border-radius: 10px;
   outline-style: none;
   min-height: 231px;
   margin-top: 10px;
   resize: none;
   padding: 10px;
 }

 .genericBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
 }
 .genericBtnInnerBox{
   width: 40%;
 }

 .btnSubmit {
   text-align: center;
 }

 .checkBox {
   width: 22px;
   height: 22px;
   border: 2px solid #d8d8d8;
   margin-right: 10px;
   background-color: #fff;
   -webkit-appearance: none;
   outline-style: none;
   cursor: pointer;
 }

 .checkBox:checked {
   background: url("../../../assets/checked.svg") no-repeat center;
 }

 .inptOther {
   display: flex;
   align-items: center;
 }
 .submitTitle {
  margin-top: 30px;
  margin-bottom: 10px;
  font-weight: 500;
 }

 .other {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 40px;
  width: 100%;
  /* position: relative; */
 }
 .inputOtherRow {
  display: flex;
  justify-content: space-between;
  width: calc(50% - 40px);
  position: relative;
 }
 .btnSearch {
  position: absolute;
  left: 101%;
  top: 100%;
  transform: translate(0, -100%);
  cursor: pointer;
 }

 .genericSubmitBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
}
.genericSubmitBtnInnerBox {
  width: 20%;
}
.searchIcon {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  background: url(../../../assets/magnifier.svg) no-repeat center;
  background-color: var(--mainColor);
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
  .violationIncidentBox {
    background-color: white;
    padding: 70px;
   }
   .mainInfor {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
   }
   .firstInfor {
    box-sizing: border-box;
    width: 100%;
    padding: 0px ;
    border-right: none;
    border-bottom: 1px solid var(--lightGrey);
    margin-bottom: 20px;
    padding-bottom: 20px;
   }
   .secondInfor {
    box-sizing: border-box;
    width: 100%;
    padding: 0px ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   }
   .labelBox {
    display: flex;
    flex-direction: column;
    font-size: 16px;
  }
  .other {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    /* position: relative; */
   }
   .inputOtherRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 50%;
    position: relative;
   }
   .inputOtherRow .inputLong {
    width: calc(98%);
   }
   .btnSearch {
    position: absolute;
    left: 100%;
   }
  
   .genericSubmitBtn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
  }
  .genericSubmitBtnInnerBox {
    width: 20%;
  }
  .genericSubmitBtnInnerBox {
    width: 200px;
  }
   .genericBtnInnerBox{
     width: 200px;
   }
}
@media screen and (max-width:767px) {
  .violationIncidentBox {
    background-color: white;
    padding: 10px;
   }
   .mainInfor {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
   }
   .firstInfor {
    box-sizing: border-box;
    width: 100%;
    padding: 0px ;
    border-right: none;
    border-bottom: 1px solid var(--lightGrey);
    margin-bottom: 20px;
    padding-bottom: 20px;
   }
   .secondInfor {
    box-sizing: border-box;
    width: 100%;
    padding: 0px ;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   }
   .other {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 40px;
    width: 100%;
    /* position: relative; */
   }
   .inputOtherRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    position: relative;
   }
   .inputOtherRow .inputLong {
    width: 90%;
   }
   .btnSearch {
    position: absolute;
    left: 100%;
   }
  
   .genericSubmitBtn {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 80px;
  }
  .genericSubmitBtnInnerBox {
    width: 20%;
  }
  .genericSubmitBtnInnerBox {
    width: 200px;
  }
   .genericBtnInnerBox{
     width: 200px;
   }
   .btnSearch {
    position: absolute;
    left: 91%;
    top: 100%;
    transform: translate(0, -100%);
    cursor: pointer;
   }
   
}
