.vehicleSearchBox {
  background-color: white;
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 24px;
  margin-bottom: 6px;
  color: var(--superDarkGrey);
}
.subTitle {
  color: var(--superDarkGrey);
  text-align: center;
}
.inputArea {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}
.inputArea .inputLong {
  width: 60%;
  margin-bottom: 20px;
}
.inputArea .inputLong input {
  height: 30px;
}
.or {
  font-size: 18px;
  font-weight: 700;
  color: var(--mainColor);
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.genericSubmitBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 80px;
}
.genericSubmitBtnInnerBox {
  width: 20%;
}
.input {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 26px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  text-indent: 5px;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
}
.closedInput {
  height: 28px;
  margin-top: 8px;
  font-size: 14px;
}
.inputLong select {
  height: 30px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .infoBox {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .vehicleSearchBox {
    padding: 10px;
    padding-bottom: 30px;
  }
  .infoBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 20px;
    margin-bottom: 6px;
  }
  .inputArea .inputLong {
    width: 60%;
    margin-bottom: 20px;
  }

  .genericSubmitBtnInnerBox {
    width: 200px;
  }
}
@media screen and (max-width:767px) {
  .vehicleSearchBox {
    padding: 10px;
    padding-bottom: 30px;
  }
  .infoBox {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .title {
    font-size: 20px;
    margin-bottom: 6px;
  }
  .inputArea .inputLong {
    width: 100%;
    margin-bottom: 20px;
  }

  .genericSubmitBtnInnerBox {
    width: 200px;
  }
}
