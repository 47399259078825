
.passPaymentStatus {
    box-sizing: border-box;
    border: 1px solid var(--specialGrey);
    height: 27px;
    width: 112px;
    outline-style: none;
    font-size: 14px;
    text-indent: 5px;
    text-align: center;
    padding: 0 5px;
    border-radius: 10px;
    border-color: var(--red);
    color: var(--red);
    cursor: pointer;
    font-weight: 500;
}

.optionPaymentStatus {
    padding: 5px 10px;
    text-align: center;
    color: black;
}

.paid {
    padding: 5px 10px;
    border: 1px solid #28AE2D;
    background-color: #E9F7EA;
    color: #88D28B;
    border-radius: 10px;
    text-align: center;
}
.refunded {
    padding: 5px 10px;
    border: 1px solid #E9A41E;
    background-color: #f8e5d4;
    color: #ac750e;
    border-radius: 10px;
    text-align: center;
}
.noCharge {
    padding: 5px 10px;
    border: 1px solid #999999;
    background-color: #f3f3f3;
    color: #333333;
    border-radius: 10px;
    text-align: center;
}
