.input {
  font-weight: 500;
}
.svg svg {
  fill: var(--mainColor);
}
.svgDisabled svg {
  fill: gray
}
.inputDisabled {
  color: rgb(172, 172, 172);
  font-weight: 500;
}
.container {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 6px;
  border-radius: 4px;
  gap: 20px;
  border: none;
  position: relative;
  cursor: pointer;
}
.header__container {
  display: flex;
  justify-content: space-between;
}
.popup {
  display: flex;
  flex-direction: row;
  position: absolute;
  z-index: 2;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #ffffff;
}
.dropdownButton {
  margin: 0 5px;
  width: 77px;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  outline-style: none;
  cursor: pointer;
  padding: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../assets/traingleDownSmall.svg) no-repeat center right;
}
.dropdownButton option {
  background: #f2f2f2;
}
.nav {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.nav__button {
  border: none;
  border-radius: 5px;
  height: 100%;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  align-items: center;
  background: #ffffff;
  cursor: pointer;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
  .popup {
    flex-direction: column;
  }
}

