.refundBox {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    text-align: center;
}
.alertPic {
    width: 50px;
}
.warning {
    font-size: 24px;
    margin-top: 20px;
}
.subtitle {
    font-size: 18px;
    line-height: 150%;
    font-weight: 500;
}
.refundBox input {
    width:min-content;
    min-width: 7rem;
    padding: 5px;
    border: none;
    border-radius: 5px;
    background-color: lightgray;
}
.tableWrapper {
    margin: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    box-sizing: border-box;
}
.tableWrapper  .table {
    width: 98%;
}
.btnBox {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 98%;
    margin-top: 30px;
}
.btnBox button {
    width: 254px;
}
.table thead th{
    background-color: var(--mainColor);
    height: 58px;
    color: white;
}
.table thead th:first-child{
    border-top-left-radius: 20px;
}
.table thead th:last-child {
    border-top-right-radius: 20px;
}

.table tbody td{
   height: 58px;
   border-bottom: 1px solid #f2f2f2;
}
.table tbody td:first-child {
    border-left: 1px solid #f2f2f2;
    border-bottom-left-radius: 20px;
}
.table tbody td:last-child {
    border-right: 1px solid #f2f2f2;
    border-bottom-right-radius: 20px;
}
.refundTitle {
    font-weight: 500;
    font-size: 18px;
    margin-bottom: 8px;
}
.refundInput input {
    height: 30px;
    background-color: #fff;
    border-radius: 10px;
    border: 3px solid var(--lightGrey);
    font-size: 18px;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .warning {
        font-size: 20px;
        margin-top: 20px;
    }
    .btnBox {
        flex-direction: column;
    }
    .btnBox button {
        margin-bottom: 10px;
    }
    .subtitle{
        font-size: 18px;
        margin-top: 20px;
        padding: 0px 20px;
    }
}
