body.watercolor {
  --mainColor: #0077be;
  --secondaryColor: #025586;
  --thirdColor: #68c6b4;
  --hoverColor: #005688;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#025586, #0077be);
}
