.input {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-width: 0;
  border-radius: 4px;
  font-weight: 500;
  background-color: #F1F1F1;
  cursor: pointer;
  align-items: center;
}
.inputDisabled {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-width: 0;
  border-radius: 4px;
  font-weight: 500;
  background-color: #F1F1F1;
  cursor: pointer;
  align-items: center;
  color: rgb(172, 172, 172);
}
.svg svg {
  fill:black;
  margin-bottom: 2px;
  
}
.svgDisabled svg {
  fill:gray;
  margin-bottom: 2px;
}
.container {
  display: flex;
  align-items: center;
  background-color: #F1F1F1;
  border-radius: 4px;
  width: 30%;
  padding: 8px;
  cursor: pointer;
}