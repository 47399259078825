.infoBox {
    margin-top: 20px;
    width: 100%;
    padding: 0 40px;
}
.picBox {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
}
.img {
    width: 90px;
    height: 160px;
    background-color: var(--lightGrey);
    margin: 10px 10px;
    cursor: pointer;
}
.title {
    font-size: 20px;
    font-weight: 500;
}
.textArea {
    width: 100%;
    border: none;
    border: 2px solid var(--lightGrey);
    border-radius: 10px;
    outline-style: none;
    min-height: 300px;
    margin-top: 10px;
    resize: none;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .infoBox {
        margin-top: 20px;
        width: 100%;
        padding: 0 10px;
    }
    .title {
        font-size: 16px;
        font-weight: 500;
    }
    .textArea {
        margin-top: 10px;
        min-height: 70vh;
        font-size: 14px;
    }
}