.alertLine {
    padding: 0px 20px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}
.title {
    font-weight: 500;
    font-size: 14px;
}