body.grandcomplex {
  --mainColor: #14628e;
  --secondaryColor: #105781;
  --thirdColor: #51bc79;
  --hoverColor: #0c3f5c;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#244164, #409dd1);
}
