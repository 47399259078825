* {
  margin: 0;
  padding: 0;
}
.active a {
  color: var(--thirdColor);
}
.active .tabLine {
  background-color: var(--thirdColor);
}

.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  margin-top: 0px;
  width: 100%;
}
.flexBox {
  box-sizing: border-box;
  width: 100%;
  padding-right: 20px;
}
.infoBox {
  width: 100%;
  box-sizing: border-box;
  position: relative;
  border-radius: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  min-width: 320px;
}
.infoTitle {
  margin: 0px;
  font-size: 30px;
  font-weight: 500;
}
.exitBtn {
  position: absolute;
  right: 10px;
  top: 10px;
  background: url(../../../assets/blackExit.svg) no-repeat center;
  width: 22px;
  height: 22px;
  border: none;
  outline-style: none;
  cursor: pointer;
}
.infoSectionBox {
  display: flex;
  margin-top: 20px;
  justify-content: space-evenly;
  width: 100%;
  flex-wrap: wrap;
}
.tabBox {
  box-sizing: border-box;
  margin-top: 20px;
  height: 80px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  border-radius: 20px 20px 0px 0px;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  min-width: 320px;
}
.tab {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex: 1;
  font-size: 18px;
  font-weight: 500;
}
.tabTitle {
  color: var(--darkGrey);
  margin-bottom: 20px;
}
.tabTitleClicked {
  color: var(--thirdColor);
  margin-bottom: 20px;
}

.tabLine {
  position: absolute;
  bottom: 10px;
  background-color: var(--lightGrey);
  height: 10px;
  width: 100%;
}

.section {
  width: 386px;
  margin: 10px 10px;
}

.listItemBox {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  padding: 10px 0px;
}
.listTitle {
  justify-content: flex-start;
  text-align: start;
}
.listContent {
  list-style: none;
  font-weight: 500;
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.circle {
  width: 15px;
  height: 15px;
  border-radius: 20px;
  margin-right: 10px;
}
.listItemBoxEdu {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}
.tablesBox {
  margin-bottom: 20px;
}

.guestHidden {
  display: none;
  flex: 0;
}
.vendorHidden {
  display: none;
  flex: 0;
}
.residentHidden {
  display: none;
  flex: 0;
}
.passStatusActive {
  border-radius: 20px;
  padding: 5px 15.66px;
  background-color: var(--green);
}
.passStatusExpired {
  border-radius: 20px;
  padding: 5px 11.115px;
  background-color: var(--red);
}
.passStatusUpcomming {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: rgb(118, 209, 118);
}
.passStatusInactive {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: rgb(118, 209, 118);
}
.passStatusIncomplete {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--lightGrey);
}
.passStatusRefunded {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: #e9a41e;
}
.passStatusDefault {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--darkGrey);
}
.stickyTd {
  position: sticky;
  left: 0px;
  top: 0px;
  height: 100%;
  text-align: center;
  border-right: 1px solid var(--lightGrey);
  z-index: 1;
  background-color: #fff;
}
.flexDiv {
  display: flex;
  align-items: center;
}
.detailsBtn {
  padding: 0px 10px;
}
.detailsBtn button {
  background-color: #fff;
}
.delete {
  padding: 0px 10px;
  height: 100%;
  border-left: 1px solid var(--lightGrey);
  height: 53px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.actionBox {
  position: absolute;
  top: 50px;
  left: 0px;
  z-index: 1;
  width: 123px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  border: 1px solid var(--specialGrey);
}
.actionBtn {
  height: 40px;
  width: 123px;
  background-color: #fff;
  border: none;
  border-bottom: 1px dashed var(--lightGrey);
  cursor: pointer;
  font-weight: 500;
  outline-style: none;
}
.actionBtn:hover {
  color: white;
  background-color: var(--mainColor);
}
.tableRadiusBox {
  overflow-x: scroll;
  overflow-y: scroll;
  height: 200px;
}
.vehicleHidden {
  display: none;
}
.complaintHidden {
  display: none;
}
.employeeHidden {
  display: none;
}
.localPopUp {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 10;
}
.documentList {
  list-style: none;
  margin-top: 50px;
  width: 90%;
  border: 1px solid #f0f0f0;
  border-radius: 10px;
  overflow: hidden;
}
.documentItem:nth-child(odd) {
  background-color: #f0f0f0;
}
.documentItem a,
.documentItem i {
  color: black;
  width: 100%;
  line-height: 2em;
  padding: 0.3em 1em;
  display: block;
}
.documentItem i {
  text-align: center;
  font-size: 14px;
  color: #aaa;
}
.documentItem a:hover {
  background-color: #d0d0d0;
}
.tdItem {
  overflow: hidden;
  text-overflow: ellipsis;
}
.passPaymentStatus {
  box-sizing: border-box;
  border: 1px solid var(--specialGrey);
  height: 27px;
  width: 112px;
  outline-style: none;
  font-size: 14px;
  text-indent: 5px;
  text-align: center;
  padding: 0 5px;
  border-radius: 10px;
  border-color: var(--red);
  color: var(--red);
  cursor: pointer;
  font-weight: 500;
}

.optionPaymentStatus {
  padding: 5px 10px;
  text-align: center;
  color: black;
}

.paid {
  padding: 5px 10px;
  border: 1px solid #28ae2d;
  background-color: #e9f7ea;
  color: #88d28b;
  border-radius: 10px;
  text-align: center;
}
.refunded {
  padding: 5px 10px;
  border: 1px solid #e9a41e;
  background-color: #f8e5d4;
  color: #ac750e;
  border-radius: 10px;
  text-align: center;
}
.noCharge {
  padding: 5px 10px;
  border: 1px solid #999999;
  background-color: #f3f3f3;
  color: #333333;
  border-radius: 10px;
  text-align: center;
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin-top: 0px;
    width: 100%;
  }
  .tab {
    font-size: 18px;
    font-weight: 500;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    margin-top: 0px;
    width: 100%;
  }
  .flexBox {
    width: 100%;
    padding-right: 20px;
  }
  .tab {
    font-size: 16px;
    font-weight: 500;
  }
}
@media screen and (max-width: 767px) {
  .infoTitle {
    font-size: 20px;
  }
  .container {
    box-sizing: border-box;
    margin-top: 0px;
    width: 100%;
  }
  .infoBox {
    box-sizing: border-box;
    position: relative;
    border-radius: 0px;
    padding: 30px 5px;
  }
  .listTitle {
    text-align: left;
    font-size: 14px;
  }
  .listContent {
    font-size: 14px;
  }
  .tab {
    flex-direction: column;
    justify-content: flex-start;
    font-size: 13px;
    font-weight: 500;
  }
  .tabTitle {
    color: var(--darkGrey);
    margin-bottom: 20px;
    margin-top: 20px;
    word-wrap: break-word;
    word-break: break-all;
  }
  .tabBox {
    border-radius: 0px;
  }
  .flexBox {
    padding-right: 0px;
  }
  .exitBtn {
    width: 9px;
  }
  .detailsBtn {
    padding: 0px 5px;
    padding-left: 0;
  }
  .delete {
    padding: 0px 8px;
    height: 100%;
    border-left: 1px solid var(--lightGrey);
    height: 53px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
