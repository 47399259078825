.button button {
    padding: 8px 10px;
    border: none;
    background-color: #F2F2F2;
    border-radius: 5px;
}
.buttonActive button {
    padding: 8px 10px;
    border: none;
    background-color: var(--darkGrey);
    color: white;
    border-radius: 5px;
}
.infoBox {
    position: relative;
}
.dropDownBox {
    position: relative;
    position: absolute;
    left: 5px;
    background-color: #fff;
    box-shadow: 0px 4px 12px 3px rgba(0, 0, 0, 0.25);
    padding: 20px 20px;
    z-index: 2;
}
.infoTitle {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 6px;
}
.triangle {
  position: absolute;
  top: -8px;
  left: -8px;
  width: 0;
  height: 0;
  border-width: 8px;
  border-style: solid;
  border-color: white transparent transparent transparent;
  transform: rotate(45deg);
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .dropDownBox {
      white-space: normal;
    }
}