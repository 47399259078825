.radiusBox {
    overflow: scroll ;
    height: 50vh;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    background-color: #f8f8f8;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
}
.tHead {
    width: 100%;
}
.tableHeader {
 width: 100%;
 height:54px ;
 font-size: 14px;
 font-weight: 500;
 background-color: var(--superDarkGrey);
 color: white;
}
.tableHeader th {
 font-weight: 500;
 min-width: 100px;
 padding: 0px 10px;
 position: sticky;
 top: 0;
 z-index: 3;
 background-color: var(--superDarkGrey);
}
.tableHeader th:first-child {
    width: 113px;
}
.tableHeader th:nth-child(8){
    width: 130px;
}
.table td {
    text-align: left;
}

.table {
    box-sizing: border-box;
    text-align: left;
    min-width: 1500px;
}
.tbody tr {
    height: 53px;
    background-color: #fff;
}
.tbody tr:hover {
    background-color: rgb(235, 235, 235);
}

.tbody tr td {
    font-size: 14px;
    text-align: left;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding: 0px 10px;
    height: 53px;

}

.paginationContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-top: 0px;
    background-color: #f8f8f8;
    padding: 10px;
    border-radius: 0px 0px 10px 10px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
}
.pageNumCenter {
    display: flex;
    flex-direction: row;
    background-color: white;
    border: 2px solid var(--lightGrey);
    border-radius: 0.5em;
    padding: 0.3em;
    padding-left: 0.6em;
    padding-right: 0.6em;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .tbody tr td {
        font-size: 14px;
    }
    .tableHeader th {
        font-size: 14px;
        font-weight: 500;
        min-width: 100px;
       }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .table {
        width: 1000px;
    }
    .tbody tr td {
        font-size: 14px;
    }
    .tableHeader th {
        font-size: 14px;
        font-weight: 500;
        min-width: 100px;
       }
}
@media screen and (max-width:767px) {
 .table {
     width: 1200px;
     border-radius: 0px;
 }
 .tbody tr td {
    font-size: 14px;
}
.tableHeader th {
    font-size: 14px;
    font-weight: 500;
    min-width: 100px;
   }
   .tableHeader {
    height:50px ;
   }
   .radiusBox {
    border-radius:0px;
}
.tableHeader th:first-child {
    width: 95px;
}
}