* {
  margin: 0;
  padding: 0;
}

.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  width: 100%;
  display: flex;
}
.infoBox {
  width: 100%;
  border-radius: 0 20px 20px 0;
}
.subHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 103px;
  width: 100%;
  border-radius: 0 20px 0 0;
}
.subHeaderLeft {
  display: flex;
  align-items: center;
}
.subHeaderRight {
  padding-right: 0px;
}
.subHeaderRight button:hover {
  background-color: var(--hoverColor);
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}

.title {
  font-size: 35px;
  font-weight: 500;
}
.mainContent {
  padding: 10px 20px 20px 20px;
  height: 100%;
}.tableHeader {
width: 100%;
height: 74px;
border-radius: 10px;
}

.tableHeaderItem {
font-size: 15px;
font-weight: 500;
}

.tableHeaderItem:first-child {
border-top-left-radius: 10px;
}

.tableHeaderItem:last-child {
border-top-right-radius: 10px;
width: 150px;
}

.item {
text-align: center;
font-size: 14px;
height: 60px;
border-bottom: 1px solid rgba(230, 230, 230);
}
.textCapitalize {
text-transform: capitalize;
}

.item:first-child {
border-left: 1px solid rgba(230, 230, 230);
}

.item:last-child {
border-right: 1px solid rgba(230, 230, 230);
}

.tableRow:last-child .item:first-child {
border-bottom-left-radius: 10px;
}

.tableRow:last-child .item:last-child {
border-bottom-right-radius: 10px;
}
.viewBtn {
  border:1px solid var(--darkGrey);
  padding: 4px 15px;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  margin-right: 40px;
}
.viewBtn:hover {
  background-color: var(--lightGrey);
  transition: .4s;
}
.delBtn {
  border: none;
  vertical-align: middle;
  cursor: pointer;
  background-color: transparent;
}
.delBtn svg {
  margin-top: 0;
  width: 16px;
  height: 16px;

}

.tableTopBar {
width: 100%;
display: flex;
flex-direction: row;
justify-content: space-between;
}

.btnExport {
display: flex;
justify-content: center;
width: 170px;
height: 36px;
background: #ffffff;
border: 1px solid var(--mainColor);
color: var(--mainColor);
font-weight: 500;
box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.08);
border-radius: 5px;
cursor: pointer;
font-size: 14px;
align-items: center;
}
.btnExport:hover {
background-color: var(--mainColor);
color: white;
transition: 0.4s;
}

.actionBtn {
border: none;
color: var(--mainColor);
background-color: transparent;
font-weight: 500;
cursor: pointer;
display: flex;
}
.actionBox {
width: 100%;
height: 100%;
display: flex;
align-items: center;
margin-top: 11px;
}
.actionBtn svg {
margin-top: 1px;
min-width: 14px;
stroke: var(--mainColor);
}
.menuContainer {
width: 240px;
background-color: #F8F8F8;
box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08);
border: 1px solid #CCCFD4;
}
.menuItem {
height: 30px;
border-bottom: 1px solid #dee2ea;
display: flex;
align-items: center;
cursor: pointer;
color: #333333;
font-size: 14px;
}
button.menuItem {
width: 100%;
background-color: inherit;
outline: none;
border-style: none;
padding-left: 1rem;
}
.menuItem:hover {
background-color: #dee2ea;
}
.itemText {
margin-left: 16px;
}
.topLine {
display: flex;
align-items: center;
justify-content: flex-end;
margin-bottom: 10px;
}
.selectBox {
margin: 0 5px;
width: 77px;
border-radius: 2px;
border: 1px solid #bdbdbd;
outline-style: none;
cursor: pointer;
padding: 3px;
-webkit-appearance: none;
-moz-appearance: none;
background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
}
.selectBox option {
font-size: 14px;
font-weight: 500;
}
.paginationBox {
display: flex;
justify-content: flex-end;
font-size: 14px;
margin-bottom: 10px;
}
.addBtn {
display: flex;
justify-content: flex-end;
margin: 20px;

}

.addBtn button {
padding: 0px 10px;
}

@media screen and (min-width:1200px) {
  
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
  
  .subHeader {
      border-radius: 0;
  }
  .mainContent {
      padding: 30px 0px;
    }
  .tableHeader {
      border-radius: 0px;
    }
  .tableHeaderItem:first-child {
      border-top-left-radius: 0px;
    }
    
    .tableHeaderItem:last-child {
      border-top-right-radius: 0px;
      width: 150px;
    }
  .tableRow:last-child .item:first-child {
      border-bottom-left-radius: 0px;
    }
    
    .tableRow:last-child .item:last-child {
      border-bottom-right-radius: 0px;
    }
    
}
@media screen and (max-width:767px) {
  .circle {
      box-sizing: border-box;
      width: 16px;
      height: 16px;
      margin-left: 20px;
  }
  .title {
      font-size: 20px;
      font-weight: 500;
  }
  .subHeader {
      border-radius: 0;
  }
  .subHeaderRight {
      padding-right: 10px;
    }
    .subHeaderRight button {
      width: 130px;
      font-size: 14px;
  }
  .mainContent {
      padding: 10px 0px;
    }
  .tableBox {
      width: 100%;
      overflow-x: scroll;
  }
  .table {
      width: 800px;
    
  }
  .tableHeader {
      border-radius: 0px;
    }
  .tableHeaderItem:first-child {
      border-top-left-radius: 0px;
    }
    
    .tableHeaderItem:last-child {
      border-top-right-radius: 0px;
      width: 150px;
    }
  .tableRow:last-child .item:first-child {
      border-bottom-left-radius: 0px;
    }
    
    .tableRow:last-child .item:last-child {
      border-bottom-right-radius: 0px;
    }
    .actionBtn svg {
      margin-top: 1px;
      width: 14px;
      height: 16px;
    }
}
