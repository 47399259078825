.btnBox {
  flex: 1;
}

.mediumSize {
  border: none;
  width: 141px;
  height: 35px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  outline-style: none;
}
.largeSize {
  border: none;
  width: 100%;
  height: 43px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  outline-style: none;
}
.mediumSize:hover {
  background-color: var(--hoverColor);
  transition-duration: 0.4s;
  color: white;
}
.largeSize:hover {
  background-color: var(--hoverColor);
  transition-duration: 0.4s;
}
.circle {
  width: 39px;
  height: 39px;
  border-radius: 1000px;
  border: 2px solid #fff;
  font-size: 24px;
  cursor: pointer;
  outline-style: none;
}
.outLineSmall {
  padding: 3px 10px;
  border: 1px solid #898e93;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
}
.outLineSmall:hover {
  background-color: black;
  color: #fff;
  transition-duration: 0.4s;
}
.exitBtn {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline-style: none;
  width: 25px;
  height: 25px;
}
.exitBtnImg {
  width: 25px;
  height: 25px;
  margin-top: 0px;
}
.exitBtnSmall {
  border: none;
  background-color: transparent;
  cursor: pointer;
  outline-style: none;
  width: 14px;
  height: 14px;
}
.exitBtnSmallImg {
  width: 14px;
  height: 14px;
  margin-top: 0px;
}
.addBtn {
  width: 25px;
  height: 25px;
  outline-style: none;
  border: none;
  cursor: pointer;
}
.addBtnImg {
  width: 25px;
  height: 25px;
  fill: var(--secondaryColor);
  margin-top: 0px;
}

.disabled {
  border: none;
  width: 100%;
  height: 43px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  outline-style: none;
}
.dropDown {
  background-color: transparent;
  border: none;
  outline-style: none;
  cursor: pointer;
  transform: rotate(90deg) scaleY(1.5);
  font-size: 16px;
  font-weight: 600;
}
.transparent {
  background-color: transparent;
  border: none;
  outline-style: none;
  cursor: pointer;
}
.leftCircle {
  border: none;
  height: 35px;
  width: 63px;
  border-radius: 20px 0px 0px 20px;
  font-size: large;
  font-weight: 600;
  outline-style: none;
  cursor: pointer;
  padding-bottom: 3px;
}
.leftCircle:hover {
  background-color: var(--hoverColor);
  transition-duration: 0.4s;
}
.rightCircle {
  border: none;
  height: 35px;
  width: 63px;
  border-radius: 0px 20px 20px 0px;
  font-size: large;
  font-weight: 600;
  outline-style: none;
  cursor: pointer;
  padding-bottom: 3px;
}
.rightCircle:hover {
  background-color: var(--hoverColor);
  transition-duration: 0.4s;
}
.alignCenter {
  text-align: center;
}
.edit {
  background-color: var(--thirdColor);
}
.editBtnImg {
  margin-top: 0px;
  width: 16px;
  height: 16px;
}
.outLineLarge {
  border: 1px solid var(--mainColor);
  width: 100%;
  height: 43px;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
  cursor: pointer;
  outline-style: none;
}
.view {
  width: 60px;
  height: 25px;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  cursor: pointer;
  outline-style: none;
}
.noBorder {
  border: none;
  padding: 5px 5px;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
}
.noBorder:hover {
  background-color: var(--specialGrey);
  color: white;
  transition: 0.4s;
}
.smallSize {
  padding: 4px 10px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  outline-style: none;
}
.smallSize:hover {
  background-color: black;
  transition: 0.4s;
}
.deleteBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  outline: none;
  cursor: pointer;
  background-color: transparent;
}
.hamburger {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline-style: none;
}
.hamburgerImg {
  margin-top: 0px;
  width: 25px;
  height: 25px;
}
.hamburgerClose {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline-style: none;
}
.hamburgerCloseImg {
  margin-top: 0px;
  width: 25px;
  height: 25px;
}
.userImg {
  margin-top: 0px;
  height: 25px;
  width: 25px;
}
.rectangle {
  outline-style: none;
  cursor: pointer;
}
.black {
  border: 1px solid var(--mediumGrey);
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .largeSize {
    font-size: 16px;
  }
  .outLineLarge {
    font-size: 16px;
  }
  .disabled {
    font-size: 16px;
  }
  .editBtnImg {
    margin-top: 0px;
    width: 15px;
    height: 15px;
  }
  .circle {
    width: 36px;
    height: 36px;
  }
}
@media screen and (max-width: 991px) {
  .largeSize {
    width: 200px;
    height: 35px;
    font-size: 16px;
  }
  .outLineLarge {
    width: 200px;
    height: 35px;
    font-size: 16px;
  }
  .disabled {
    width: 200px;
    height: 35px;
    font-size: 16px;
  }
  .editBtnImg {
    margin-top: 0px;
    width: 15px;
    height: 15px;
  }
  .circle {
    width: 36px;
    height: 36px;
  }
}
@media screen and (max-width: 767px) {
  .circle {
    width: 30px;
    height: 30px;
    border-radius: 39px;
    border: 2px solid #fff;
    font-size: 18px;
  }
  .exitBtnImg {
    width: 20px;
    height: 20px;
  }
  .editBtnImg {
    margin-top: 0px;
    width: 12px;
    height: 12px;
  }
  .smallSize {
    padding: 4px 6px;
    font-size: 14px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    outline-style: none;
  }
  .smallSize:hover {
    background-color: black;
    transition: 0.4s;
  }
}

.outline_solid {
  color: var(--mainColor);
  background-color: transparent;
  border: 1px solid var(--mainColor) !important;
  border-radius: 4px;
  box-shadow: 0px 1px 1px var(--mainColor);
}

.outline_solid:hover{
  color: #fff;
  box-shadow: 0px 1px 1px var(--hoverColor) !important;
  border: 1px solid var(--hove) !important;
}