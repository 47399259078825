.smallSize {
  display: flex;
  height: 23px;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--lightGrey);
  outline-style: none;
  font-size: 14px;
}
.smallSize:disabled {
  background-color: transparent;
}
.smallSize:focus {
  border-bottom: 2px solid var(--mainColor);
}
.largeSize {
  display: flex;
  height: 32px;
  width: 100%;
  border: none;
  border-bottom: 2px solid var(--lightGrey);
  outline-style: none;
  font-size: 16px;
}
.largeSize:focus {
  border-bottom: 2px solid var(--mainColor);
}
.labelSmall {
  font-size: 17px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.labelLarge {
  font-size: 18px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.inputBox {
  flex: 1;
}
.labelClosedInput {
  font-size: 14px;
  font-weight: 500;
}
.closedInput {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  text-indent: 4px;
}
.labelCheckBox {
  width: 180px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}
.checkBox {
  width: 22px;
  height: 22px;
  border: 2px solid var(--specialGrey);
  outline-style: none;
  -webkit-appearance: none;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.checkBox:checked {
  background-color: var(--mainColor);
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .labelLarge {
    font-size: 16px;
  }
  .largeSize {
    height: 28px;
    width: 100%;
    font-size: 14px;
  }
}
@media screen and (max-width: 767px) {
  .labelLarge {
    font-size: 16px;
  }
  .largeSize {
    height: 28px;
    width: 100%;
    font-size: 14px;
  }
  .closedInput {
    height: 28px;
    margin-top: 8px;
    font-size: 14px;
  }
  .labelSmall {
    font-size: 14px;
  }
}
