* {
    margin: 0;
    padding: 0;
   }
.box {
    position: relative;
    width: calc( 100% - 20px);
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    border-radius: 20px;
    height: 700px;
    display: flex;
}
.exitBtn {
    position: absolute;
    right: 10px;
    top: 10px;
    background: url(../../../assets/blackExit.svg) no-repeat center;
    width: 22px;
    height: 22px;
    border: none;
    outline-style: none;
    cursor: pointer;
}
.subNav {
    width: 200px;
    min-width: 150px;
    border-radius: 20px 0px 0px 20px;
}
.btnBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 50px;
}
.btn {
    width: 100%;
    height: 60px;
    color: white;
    border: none;
    outline-style: none;
    cursor: pointer;
    text-align: start;
    padding-left: 10px;
    display: flex;
    align-items: center;
}
.btn:hover {
    background-color: var(--secondaryColor);
    transition: 0.4s;
}
.icon {
    width: 24px;
    margin-right: 8px;
}
.infoBox {
    margin-left: 10%;
}
.userBox {
    margin-top: 40px;
    display: flex;
    align-items: center;
   
}
.userNameBox {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
}
.userName {
    margin: 0px;
    margin-bottom: 10px;
    font-weight: 500;
    font-size: 32px;
}
.userCompany {
    font-size: 18px;
    font-weight: 500;
}
.personalInfoBox {
    margin-top: 40px;
    margin-left: 127px;
}
.firstLine {
    display: flex;
    align-items: center;
    position: relative;
}
.editBtn {
    position: absolute;
    left: -40px;
    top: 0px;
    width: 30px;
    height: 30px;
    border-radius: 31px;
    border: none;
    outline-style: none;
    cursor: pointer;
   
}
.editBtn img {
    margin-top: 3px;
}
.personalTitle {
    font-weight: 500;
    font-size: 26px;
}
.personalInfoBox ul {
    display: flex;
    margin-bottom: 15px;
}
.personalInfoBox ul li {
    list-style: none;
}
.form {
    margin-top: 20px;
}
.listTitle {
    font-size: 17px;
    margin-right: 10px;
}
.listItem {
    font-weight: 700;
}
.btnView {
    margin-top: 20px;
    padding-left: 0px;
}
.btnView button {
    width: 100%;
}
.rolesBox {
    display: flex;
    flex-direction: column;
}
.rolesBox li {
    margin-bottom: 5px;
}
.roles a {
   text-decoration: underline;
   color:var(--mainColor);
   font-weight: 700;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .userImg svg {
        width: 60px;
    }
    .userName {
        font-size: 26px;
    }
    .userCompany {
        font-size: 16px;
    }
    .personalInfoBox {
        margin-top: 40px;
        margin-left: 83px;
    }
}
@media screen and (max-width:767px) {
    .alert {
        margin-right: 40px;
        margin-left: 20px;
    }
    .box {
        width: 100%;
        border-radius: 0px;
        height: 100vh;
        flex-direction: column;
        min-width: 320px;
        margin-top: 0px;
    }
    .subNav {
        width: 100%;
        min-width: 150px;
        border-radius: 0px;
    }
    .btnBox {
        margin-top: 0px;
    }
    .btn {
        height: 40px;
        font-size: 14px;
    }
    .icon {
        width: 20px;
        margin-right: 8px;
    }
    .infoBox {
        margin-left: 20px;
    }
    .userBox {
        margin-top: 0px;
        display: flex;
        align-items: center;
       
    }
    .userImg svg {
        width: 40px;
    }
    .userName {
        font-size: 20px;
    }
    .userCompany {
        font-size: 16px;
    }
    .personalInfoBox {
        margin-top: 10px;
        margin-left: 50px;
    }

    .editBtn {
        left: -40px;
        width: 25px;
        height: 25px;
       
    }
    .editBtn img {
        margin-top: 3px;
    }
    .personalTitle {
        font-size: 20px;
    }
    .personalInfoBox ul {
        display: flex;
        margin-bottom: 8px;
    }
    .listTitle {
        font-size: 16px;
    }
    .listItem {
        font-weight: 700;
        font-size: 16px;
    }
    .btnView {
        margin-top: 20px;
        padding-left: 0px;
    }
    .btnView button {
        width: 200px;
    }
    .userNameBox {
        margin-left: 10px;
    }
    .exitBtn {
        right: 20px;
        top: 168px;
        width: 9px;
    }
}
