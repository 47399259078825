.dateDropDownBox {
    position: relative;
}
.buttonSelectDown button {
  background: url(../../assets/selectDown.svg) no-repeat center right;
  width: 170px;
  height: 30px;
  padding-left: 5px;
  margin: 5px 0px;
  border: none;
  background-color: rgb(240, 240, 240);
  border-radius: 5px;
  text-align: left;
}
.buttonSelectUp button {
  background: url(../../assets/selectUp.svg) no-repeat center right;
  width: 170px;
  height: 30px;
  padding-left: 5px;
  margin: 5px 0px;
  border: none;
  background-color: var(--mainColor);
  color: white ;
  border-radius: 5px;
  text-align: left;
}
.dropDownBox {
    position: absolute;
    top: 40px;
    display: flex;
    z-index: 2;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    padding: 10px;
  
}
.dateInput {
    font-size: 14px;
    width: 180px;
}
.dateInput input {
    background-color: rgb(240, 240, 240);
    border: none;
    height: 26px;
    margin-bottom: 10px;
    width: 180px;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .buttonSelectDown button {
        width: 160px;
        height: 26px;
        font-size: 14px;
      }
      .buttonSelectUp button {
        width: 160px;
        height: 26px;
        font-size: 14px;
      }
  
}