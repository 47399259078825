* {
  margin: 0;
  padding: 0;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  border-radius: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 40px;
}
.textBox {
  display: flex;
  align-items: center;
}
.subHeader {
  /* position: relative; */
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 103px;
  border-radius: 20px 20px 0 0;
  padding-right: 20px;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}
.subHeaderTitle {
  font-size: 35px;
  font-weight: 500;
}
.backBtn {
  width: 141px;
  /* position: absolute; */
  /* right: 45px; */
}
.infoBox {
  width: 80%;
  box-sizing: border-box;
  padding: 50px 100px;
}
.update {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.about {
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
  text-justify: distribute;
  margin-bottom: 40px;
}
.catelogue {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
}
.list {
  font-size: 16px;
  margin-bottom: 20px;
  text-decoration: underline;
}
.catalogueBox {
  margin-bottom: 44px;
}
.title {
  font-size: 30px;
  margin-bottom: 30px;
}
.subtitle {
  font-size: 18px;
  margin-bottom: 15px;
}
.text {
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
  text-justify: distribute;
  margin-bottom: 20px;
}
.textBold {
  font-size: 16px;
  line-height: 160%;
  font-weight: 600;
  text-align: justify;
  text-justify: distribute;
  margin-bottom: 15px;
}
.listNoLine {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 160%;
}
.listNoLine a {
  color: var(--mainColor);
  margin-left: 5px;
}
.listBox {
  width: 70%;
  text-align: justify;
  text-justify: distribute;
  margin-bottom: 44px;
}
.innerTitle {
  font-size: 16px;
  margin-bottom: 15px;
}
.listTitle {
  margin-bottom: 10px;
}
.titieInfoPart {
  font-size: 24px;
  font-weight: 500;
}
.textInfoPart {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  text-align: justify;
  text-justify: distribute;
  margin-bottom: 20px;
  margin-top: 15px;
}
.specialTitle {
  margin-bottom: 20px;
  font-size: 26px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .formBox {
    width: 64%;
  }
  .alert {
    width: 156.25%;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .box {
    margin-top: 230px;
  }
  /* .about {
        text-align: left;
    }
    .text {
        text-align: left;
    }
    .textBold {
        text-align: left;
    }
    .listBox {
        width: 80%;
        text-align: left;
        margin-bottom: 44px;
    }
    .textInfoPart {
     
        text-align: left;
      
    } */
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .subHeader {
    border-radius: 0;
  }
  .box {
    width: 100%;
    display: flex;
    margin-top: 230px;
    border-radius: 0px;
  }
  .infoBox {
    padding: 10px 50px;
  }
  .listBox {
    width: 100%;
    text-align: justify;
    margin-bottom: 44px;
  }
  .backBtn button {
    width: 120px;
  }
}
@media screen and (max-width: 767px) {
  .circle {
    width: 13px;
    height: 13px;
    margin-left: 10px;
    margin-right: 5px;
  }
  .subHeaderTitle {
    font-size: 20px;
    font-weight: 500;
  }
  .subHeader {
    border-radius: 0;
  }
  /* .box {
        width: 100%;
        display: flex;
        margin-top: 20px;
        border-radius: 0px;
     
     } */
  .box {
    width: 100%;
    display: flex;
    margin-top: 44px;
    border-radius: 0px;
    flex-direction: column;
  }
  .backBtn {
    position: absolute;
    width: 70px;
    right: 10px;
  }
  .infoBox {
    box-sizing: border-box;
    width: 90%;
    padding: 10px 20px;
    min-width: 320px;
  }
  .update {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }
  .about {
    font-size: 14px;
    line-height: 160%;
    text-align: left;
    margin-bottom: 20px;
  }
  .catelogue {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .list {
    font-size: 14px;
    margin-bottom: 20px;
    text-decoration: underline;
  }
  .catalogueBox {
    margin-bottom: 20px;
  }
  .title {
    font-size: 20px;
    margin-bottom: 15px;
  }
  .subtitle {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .text {
    font-size: 14px;
    line-height: 160%;
    text-align: left;
    margin-bottom: 20px;
  }
  .textBold {
    font-size: 14px;
    line-height: 160%;
    font-weight: 600;
    text-align: left;
    margin-bottom: 15px;
  }
  .listNoLine {
    font-size: 14px;
    margin-bottom: 15px;
    line-height: 160%;
  }
  .listNoLine a {
    color: var(--mainColor);
    margin-left: 5px;
    word-wrap: break-word;
  }
  .listBox {
    width: 100%;
    text-align: left;
    margin-bottom: 30px;
  }
  .innerTitle {
    font-size: 16px;
    margin-bottom: 15px;
  }
  .listTitle {
    margin-bottom: 10px;
  }
  .titieInfoPart {
    font-size: 24px;
    font-weight: 500;
  }
  .textInfoPart {
    font-weight: 400;
    font-size: 16px;
    line-height: 160%;
    text-align: left;
    margin-bottom: 20px;
    margin-top: 15px;
  }
  .specialTitle {
    margin-bottom: 20px;
    font-size: 26px;
  }
  .backBtn button {
    width: 70px;
  }
}
