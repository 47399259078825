.titleButtonOpen {
    background: url(../../assets/selectUpBlack.svg) no-repeat right;
    background-color: var(--backgroungColor);
    width: 100%;
    border: none;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid var(--lightGrey);
    border-bottom: 1px solid var(--lightGrey);
}
.titleButtonClose {
    background: url(../../assets/selectDownBlack.svg) no-repeat right;
    background-color: var(--backgroungColor);
    width: 100%;
    border: none;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    border-top: 1px solid var(--lightGrey);
    border-bottom: 1px solid var(--lightGrey);
}
.title {
    text-transform: capitalize;
}

.listItem {
    display: flex;
    justify-content: space-between;
    padding: 10px;
}
.listItem li {
    list-style: none;
}