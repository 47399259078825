.loading {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 600;
  color: var(--specialGrey);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader {
  box-sizing: border-box;
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: space-around;
  /* border: 1px solid grey; */
  position: relative;
}
/* .loader::before {
    position: absolute;
    content: '';
    left: 20px;
    right: 20px;
    border-top: 2px solid blue;
    top: 50%;
    transform: translateY(-50%);
} */
.loader > div {
  width: 20px;
  height: 20px;
  background-color: var(--mainColor);
  transform: rotate(45deg);
  animation: loader-1 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate;
}
div.two {
  animation-delay: 200ms;
}
div.three {
  animation-delay: 400ms;
}

@keyframes loader-1 {
  from {
    transform: rotate(45deg) scale(1);
  }

  to {
    transform: rotate(45deg) scale(0.8);
  }
}

@media screen and (min-width: 1200px) {
}
