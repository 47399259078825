* {
    margin: 0;
    padding: 0;
}
.box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.flexBox {
    display: flex;
    width: 80%;
    justify-content: space-evenly;
    margin-top: 40px;
    flex-wrap: wrap;
}
.infoBox {
    width: 30%;
    border-radius: 20px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    min-width: 300px;
    margin: 0px 10px;
}
.header {
    border-radius: 20px 20px 0px 0px;
    height: 44px;
    display: flex;
    align-items: center;
}
.headerTitle {
    font-weight: 500;
    margin-left: 10px;
}
.inputBox {
    padding: 10px 20px;
}
.label {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 500;
    color: var(--superDarkGrey);
}
.input {
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid var(--specialGrey);
    height: 26px;
    overflow: hidden;
    width: 100%;
    outline-style: none;
    margin-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    text-indent: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
}
.inputSelect {
    text-transform: capitalize;
}
.inputArea {
    margin-bottom: 8px;
}
.btn {
    width: 200px;
    margin-top: 40px;
}
.inputArea input {
    height: 30px;
    font-size: 16px;
}
.inputArea select {
    height: 30px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .infoBox {
        margin-bottom: 10px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .infoBox {
        width: 100%;
        height: 320px;
        border-radius: 20px;
        box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
        min-width: auto;
        margin-bottom: 10px;
    }
}
@media screen and (max-width:767px) {
    .flexBox {
        display: flex;
        width: 100%;
        justify-content: space-evenly;
        margin-top: 20px;
        flex-wrap: wrap;
    }
    .infoBox {
        width: 100%;
        height: 320px;
        border-radius: 0px;
        box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
        min-width: auto;
        margin-bottom: 10px;
    }
    .headerTitle {

        font-size: 14px;
    }
    .header {
        border-radius: 0px;
        height: 44px;
        display: flex;
        align-items: center;
    }
    .inputArea input {
        height: 30px;
    }
    .inputArea select {
        height: 30px;
    }
}