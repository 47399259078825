.dropDownBox {
  display: flex;
  flex-direction: column;
  height: 300px;
  overflow-y: scroll;
  margin-top: 10px;
  margin-bottom: 10px;
}
/* To disable scrollBar */
.dropDownBox {
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
}
.dropDownBox::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}
/* */
.dropdowns {
  display: flex;
  flex-direction: row;
}
.button {
  background-color: #ffffff;
  border: none;
  width: 76px;
  height: 25px;
  margin-top: 2px;
  margin-bottom: 2px;
}
.container {
  position: relative;
}
.popup {
  position: absolute;
  top: 40px;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  z-index: 2;
}
.active {
  background-color: #3e81d0;
  color: #ffffff;
}
.input {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 50px;
  cursor: pointer;
}
.inputDisabled {
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 5px;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  gap: 50px;
  cursor: pointer;
  background-color: #f1f1f1;
  color: gray;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  gap: 20px;
}
.cancelButton {
  flex: 1;
  background: #f1f1f1;
  border-radius: 5px;
  color: #3E81D0;
  border: none;
  padding: 5px;
  cursor: pointer;
}
.confirmButton {
  flex: 1;
  background: #3E81D0;
  border-radius: 5px;
  color: #ffffff;
  border: none;
  padding: 5px;
  cursor: pointer;
}
.title {
  display: block;
  padding-left: 20px;
}
