.form {
    margin-top: 20px;
    width: 48%;
}
.alertPic {
    margin-right: 10px;
}
.alert {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
}
.inputBox {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
}
.inputLong {
    width: 100%;
}
.inputLong p{
    color: var(--superDarkGrey);
}
.inputShort {
    width: 48%;
}
.btnBox {
    width: 100%;
    display: flex;
    justify-content: center;
}
.btn {
    width: 200px;
    margin-top: 40px;
}
.rentalTitleBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--lightGrey);
    margin-bottom: 10px;
}
.rentalTitle {
    font-size: 15px;
    font-weight: 700;
    color: var(--superDarkGrey);
    margin: 10px 0px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .form {
        width: 80%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .form {
        width: 80%;
    }
}
@media screen and (max-width:767px) {
    .form {
        width: 90%;
    }
}