* {
  margin: 0;
  padding: 0;
}

.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}
.searchBox {
  box-sizing: border-box;
  padding-left: 12px;
  padding-right: 12px;
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 200px;
  min-height: 400px;
  border-radius: 20px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
}
.menuItem {
  height: 30px;
  border-bottom: 1px solid #dee2ea;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #333333;
  font-size: 14px;
}
.menuContainer {
  width: 140px;
  background-color: #F8F8F8;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #CCCFD4;
}
.actionBtnMenu {
 border: none;
 color: var(--mainColor);
 background-color: transparent;
 font-weight: 500;
 cursor: pointer;
 display: flex;
}

.actionBoxMenu {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  padding-top: 12px;
}
.actionBtnMenu svg {
  margin-top: 1px;
  min-width: 14px;
  stroke: var(--mainColor);
}
.actionBtnDownload {
  height: 40px;
  width: 123px;
  background-color: #ffffff;
  border: none;
  border-bottom: 1px dashed var(--lightGrey);
  cursor: pointer;
  font-weight: 500;
  outline-style: none;
  color: rgb(42, 116, 46);
}
.actionBtnDownload:hover {
  color: white;
  background-color: var(--mainColor);
}
.gridSearch {
  border-radius: 5px;
  height: 36px;
  background-color: #f5f5f5;
  border: 1px solid #c2c2c2;
  font-size: 14px;
  outline-style: none;
  line-height: 36px;
  width: 20%;
  padding: 10px;
  min-width: 200px;
  margin-bottom: 10px;
}
.tableContainer {
  width: 100%;
  padding-right: 20px;
  padding-left: 0px;
}
.numInputBox {
  box-sizing: border-box;
  width: 100%;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  padding: 8px 0px;
  border-radius: 10px;
  margin-bottom: 10px;
  display: flex;
  justify-content: flex-end;
}
.inputBox {
  font-size: 16px;
  font-weight: 500;
  margin-right: 40px;
}
.selectBox {
  margin: 0 5px;
  width: 77px;
  border-radius: 2px;
  border: 1px solid #bdbdbd;
  outline-style: none;
  cursor: pointer;
  padding: 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: url(../../../assets/traingleDownSmall.svg) no-repeat center right; */
}
.selectBox option {
  font-size: 14px;
  font-weight: 500;
}
.radiusBox {
  margin-top: 10px;
  width: 100%;
  height: 68vh;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  overflow: scroll;
  scroll-behavior: smooth;
  overscroll-behavior: contain;
  background-color: rgb(240, 239, 239);
}
.table {
  white-space: nowrap;
  margin: 0;
  border-collapse: separate;
  border-spacing: 0;
  table-layout: fixed;
  min-width: 1300px;
}
.tableLink {
  color: var(--mainColor);
}
.tableLink:visited {
  color: var(--mainColor);
}
.tableLink:hover {
  color: var(--secondaryColor);
}

.tHead {
  width: 100%;
}
.tableHeader {
  width: 100%;
  height: 54px;
}
.tableHeader th {
  position: sticky;
  top: 0;
  z-index: 2;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--superDarkGrey);
  text-align: left;
  padding: 0px 10px;
}
.theaderItemDropDown {
  width: 30px;
}
.tdItemsDropDown {
  width: 30px;
  border-bottom: 1px solid rgb(230, 230, 230);
  /* background: url(../../../assets/traingleDropDownMenu.svg) no-repeat scroll right center transparent; */
  cursor: pointer;
  text-align: center;
}
.theaderItemCheckBox {
  width: 40px;
}
.checkboxLayout {
  width: 100%;
}
.theaderItemCheckBox input {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 3px;
  border: 2px solid var(--specialGrey);
  outline-style: none;
  cursor: pointer;
}
.theaderItemCheckBox input:checked {
  background-color: var(--superDarkGrey);
}
.tdItemsCheckBox {
  width: 40px;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 10px;
}
.tdItemsCheckBox input {
  -webkit-appearance: none;
  width: 15px;
  height: 15px;
  background-color: #fff;
  border-radius: 3px;
  border: 2px solid var(--specialGrey);
  outline-style: none;
  cursor: pointer;
}
.tdItemsCheckBox input:checked {
  background-color: var(--superDarkGrey);
}
.tableHeader th:first-child {
  border-radius: 20px 0px 0px 0px;
  width: 100px;
  text-align: center;
}
.tableHeader th:last-child {
  border-radius: 0px 5px 0px 0px;
}
.tableHeader th:nth-child(4) {
  /* border-radius: 20px 0px 0px 0px; */
}
.firstTh {
  background-color: #fff;
  width: 80px;
  text-align: center;
}
.center {
  position: sticky;
  left: 0px;
  top: 0px;
  height: 100%;
  text-align: center;
  border-right: 1px solid var(--lightGrey);
  z-index: 1;
  background-color: #fff;
}
.detailBtnBox {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}

.tdItems {
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 0px 10px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  height: 53px;
}
.tdItems button {
  background-color: #fff;
}
.row {
  height: 53px;
  position: relative;
}
.row:hover {
  background-color: rgb(235, 235, 235);
}
.inputShort {
  width: 70%;
  min-width: 156px;
  margin-bottom: 10px;
}
.inputLong {
  width: 100%;
  margin-bottom: 10px;
}
.removeMargin {
  margin-bottom: 0;
}
.btnBox {
  margin-top: 10px;
}
.passStatus {
  border-radius: 20px;
  padding: 5px 10%;
}
.labelBox {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 500;
}
.select {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 30px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 16px;
  text-indent: 5px;
  -webkit-appearance: none;
  -moz-appearance: none;
  /* background: url(../../../assets/traingleDownSmall.svg) no-repeat center right; */
  cursor: pointer;
}
.pageBox {
  box-sizing: border-box;
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  min-width: 320px;
  margin-bottom: 20px;
}
.pageInput {
  margin: 0 5px;
  width: 42px;
  border-radius: 8px;
  border: 2px solid #bdbdbd;
  outline-style: none;
  cursor: pointer;
  padding: 1px 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.loading {
  position: absolute;
  margin-top: 10px;
  margin-left: 10px;
  font-weight: 600;
  color: var(--specialGrey);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.loader {
  box-sizing: border-box;
  width: 100px;
  height: 20px;
  display: flex;
  justify-content: space-around;
  /* border: 1px solid grey; */
  position: relative;
}
/* .loader::before {
       position: absolute;
       content: '';
       left: 20px;
       right: 20px;
       border-top: 2px solid blue;
       top: 50%;
       transform: translateY(-50%);
   } */
.loader > div {
  width: 20px;
  height: 20px;
  background-color: var(--mainColor);
  transform: rotate(45deg);
  animation: loader-1 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite
    alternate;
}
div.two {
  animation-delay: 200ms;
}
div.three {
  animation-delay: 400ms;
}

@keyframes loader-1 {
  from {
    transform: rotate(45deg) scale(1);
  }

  to {
    transform: rotate(45deg) scale(0.8);
  }
}
.rentalBox {
  display: flex;
  flex-wrap: wrap;
  padding-left: 5px;
}
.rentalTable {
  /* width: 480px;
  min-width: auto; */
  margin: 10px;
  box-sizing: border-box;
  text-align: left;
}
.grayBackground {
  background-color: #f2f2f2;
  margin: 5px 5px;
  box-sizing: border-box;
}
.rentalThead th {
  background-color: #f2f2f2;
  width: 160px;
  padding-left: 10px;
  padding-right: 10px;
  height: 30px;
  font-weight: 500;
  color: var(--darkGrey);
  font-size: 14px;
}

.rentalThead td {
  text-align: left;
  font-weight: 500;
  padding-left: 10px;
  font-size: 14px;
}
.rentalTable td {
  background-color: #f2f2f2;
  border-bottom: none;
  /* overflow: hidden; */ 
  text-overflow: ellipsis;
  height: 30px;
  font-weight: 500;
  text-align: left;
  padding-left: 10px;
  font-size: 14px;
  border: none;
}
.rentalTable tr {
  height: 30px;
}
.wristbandValueBox {
  background-color: #f2f2f2;
  padding: 6px 12px;
  border-radius: 2px;
}
.numInputBox {
  align-items: center;
}
.actionBtnBox {
  height: 30px;
  width: 100px;
  display: flex;
  justify-content: left;
  padding-top: 5px;
  background-color: inherit;
  outline-style: none;
  border-style: none;
  padding-left: 5px;
}
.actionBtnBox td {
  background-color: inherit;
  outline-style: none;
  border-style: none;
}

.actionBox {
    position: absolute;
    top: -10px;
    left: 110px;
    z-index: 13;
    width: 160px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    border: 1px solid var(--specialGrey);
}
.actionBtn {
    height: 40px;
    width: 160px;
    background-color: #fff;
    border: none;
    border-bottom: 1px dashed var(--lightGrey);
    cursor: pointer;
    font-weight: 500;
    outline-style: none;
    margin: 0px !important;
}
.actionBtn:hover {
    color: white;
    background-color: var(--mainColor);
}
.marginTop {
  margin-top: 10px;
}
.passStatusActive {
  border-radius: 20px;
  padding: 5px 15.66px;
  background-color: var(--green);
}
.passStatusExpired {
  border-radius: 20px;
  padding: 5px 11.115px;
  background-color: var(--red);
}
.passStatusUpcomming {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: rgb(118, 209, 118);
}
.passStatusInactive {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: rgb(118, 209, 118);
}
.passStatusIncomplete {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--lightGrey);
}
.passStatusRefunded {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: #E9A41E;
}
.passStatusDefault {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--darkGrey);
}
.passActionBox {
  position: sticky;
  left: 0px;
  top: 0px;
  height: 54px;
  text-align: center;
  border-right: 1px solid var(--lightGrey);
  z-index:1;
  background-color: #fff;
  border-bottom: 1px solid var(--lightGrey);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.passActionBox button {
  margin-left: 10px;
}
.btnExport {
  display: flex;
  justify-content: center;
  width: 170px;
  height: 36px;
  background: #ffffff;
  border: 1px solid var(--mainColor);
  color: var(--mainColor);
  font-weight: 500;
  box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.08);
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  align-items: center;
  margin-left: 20px;
}
.btnExport:hover {
  background-color: var(--mainColor);
  color: white;
  transition: 0.4s;
}
.topLine {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
  flex-wrap: wrap;
}
.topLineRight {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .container {
    margin-top: 0px;
    width: 100%;
  }

  .radiusBox {
    margin-right: 0px;
    width: 100%;
  }
  .numInputBox {
    width: 100%;
  }
  .rentalBox {
    min-width: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .rentalTable {
    width: 480px;
    min-width: auto;
    margin: 10px;
  }
  .rentalThead th {
    background-color: #f2f2f2;
    width: 160px;
    padding-left: 10px;
    height: 30px;
    font-weight: 500;
    color: var(--darkGrey);
  }

  .rentalThead td {
    font-weight: 500;
  }
  .rentalTable td {
    background-color: #f2f2f2;
    border-bottom: none;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    font-weight: 500;
  }
  .rentalTable tr {
    height: 30px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .container {
    margin-top: 0px;
    width: 100%;
  }

  .radiusBox {
    margin-right: 0px;
    width: 100%;
    height: 60vh;
  }
  .numInputBox {
    width: 100%;
  }
  .rentalBox {
    min-width: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .rentalTable {
    width: 480px;
    min-width: auto;
    margin: 10px;
  }
  .rentalThead th {
    background-color: #f2f2f2;
    width: 160px;
    padding-left: 10px;
    height: 30px;
    font-weight: 500;
    color: var(--darkGrey);
  }

  .rentalThead td {
    font-weight: 500;
  }
  .rentalTable td {
    background-color: #f2f2f2;
    border-bottom: none;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    font-weight: 500;
  }
  .rentalTable tr {
    height: 30px;
  }
}
@media screen and (max-width: 767px) {
  .container {
    margin-top: 0px;
    width: 100%;
    flex-direction: column;
  }

  .searchBox {
    height: auto;
    padding-top: 15px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    border-radius: 0px;
    min-width: 320px;
  }
  .inputShort {
    min-width: 156px;
    margin-bottom: 10px;
  }
  .inputLong {
    margin-bottom: 0px;
  }
  .btnBox {
    margin-top: 15px;
  }
  .radiusBox {
    position: static;
    margin-left: 0px;
    margin-top: 10px;
    width: 100%;
    border-radius: 0;
    height: 40vh;
  }
  .table {
    min-width: 1300px;
  }
  .tableHeader th:first-child {
    border-radius: 0px;
  }
  .tableHeader th:last-child {
    border-radius: 0px;
  }
  .tableHeader th:nth-child(5) {
    border-radius: 0px;
  }
  .tableHeader {
    height: 50px;
  }
  .tableHeader th {
    font-size: 14px;
  }
  .tdItems {
    font-size: 14px;
  }
  .tableContainer {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-bottom: 40px;
  }
  .numInputBox {
    position: static;
    min-width: 320px;
    border-radius: 0px;
    margin-bottom: 0px;
    width: 100%;
    margin-bottom: -10px;
  }
  .inputBox {
    font-size: 14px;
    margin-right: 20px;
  }
  .labelBox {
    font-size: 14px;
  }
  .select {
    height: 24px;
    margin-top: 5px;
    font-size: 14px;
  }
  .pageInput {
    margin: 0 5px;
    width: 30px;
    border-radius: 8px;
    border: 2px solid #bdbdbd;
    outline-style: none;
    cursor: pointer;
    padding: 0px 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
  }

  .rentalBox {
    min-width: auto;
    display: flex;
    flex-wrap: wrap;
  }
  .rentalTable {
    width: 400px;
    min-width: auto;
    margin: 10px;
  }
  .rentalThead th {
    background-color: #f2f2f2;
    width: 140px;
    padding-left: 10px;
    height: 30px;
    font-weight: 500;
    color: var(--darkGrey);
  }

  .rentalThead td {
    font-weight: 500;
  }
  .rentalTable td {
    background-color: #f2f2f2;
    border-bottom: none;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 30px;
    font-weight: 500;
  }
  .rentalTable tr {
    height: 30px;
  }
}

.exportPart {
  position: relative;
}

.ExportBtn {
  /* background: url(../../../assets/selectDownWhite.svg) no-repeat right center; */
  background-color: var(--thirdColor);
  padding: 10px 30px 10px 10px;
  margin-right: 15px;
  font-size: 14px;
  text-align: left;
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: 500;
  cursor: pointer;
}

.dropDown {
  width: 180px;
  top: 40px;
  position: absolute;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: #c6c6c6;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, 0.08);
  z-index: 3;
}
.dropDown button:last-child {
  border-bottom: none;
}
.dropDown button {
  display: block;
  width: 100%;
  border: none;
  text-align: left;
  list-style: none;
  padding: 8px 10px;
  background-color: #fff;
  border-bottom: 1px solid #c6c6c6;
  cursor: pointer;
}
.dropDown button:hover {
  background-color: var(--backgroungColor);
}
