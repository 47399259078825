.alertLine {
  padding: 2px 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
  border-radius: 5px;
  -webkit-animation: fadeInDown 0.3s;
  animation: fadeInDown 0.3s;
}
.title {
  font-weight: 500;
  font-size: 14px;
}
@keyframes fadeInDown {
  0% {
    -webkit-transform: translate3d(0, -10%, 0);
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    transform: none;
    opacity: 1;
  }
}

@-webkit-keyframes fadeInDown {
  0% {
    -webkit-transform: translate3d(0, -10%, 0);
    opacity: 0;
  }
  100% {
    -webkit-transform: none;
    opacity: 1;
  }
}
