.headerLogoMiddle {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 145px;
    min-width: 320px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    position: fixed;
    z-index: 2;
}
.header {
    box-sizing: border-box;
    display: flex;
    width: 100%;
    min-width: 320px;
    align-items: center;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    position: fixed;
    z-index: 10;
}
.invisibleBoxApper {
    flex: 0.2;
}
.logoBoxLarge {
    flex: 0.6;
    display: flex;
    justify-content: center;
}
.logoBoxSmall {
    flex: 0.1;
    display: flex;
    justify-content: center;
}
.logoMiddle {
    height: 117.47px;
    margin-top:14px ;
}
.logoLeft {
    height: 90px;
    /* width: 300px; */
    margin-left: 20px;
}

.profileLogoMiddle {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 145px;
    flex: 0.2;

}
.profileLogoLeft {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 12px;
    height: 100px;
    flex: 0.15;
}
.language {
    margin-right: 10px;
    width: 90px;
    height: 30px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    outline-style: none;
    font-size: 14px;
}
.language:hover {
    background-color: var(--hoverColor);
    transition: 0.4s;
}
.language:hover .languageIcon svg {
    fill: var(--hoverColor);
}

.languageIcon svg {
    width: 21px;
    margin: 0 5px;  
    margin-top: 4px; 
    fill: var(--thirdColor);
}
.languageWithMargin {
    margin-bottom: 3px;
}
.profileBtnApper {
    visibility: visible;
}
.profileBtnDisapper {
    visibility: hidden;
}
.nav {
    flex: 0.8;
    display: flex;
    justify-content: space-evenly;
}
.navItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    height: 34px;
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 25px;
}
.navItem:hover {
    background-color: var(--secondaryColor);
    transition: .9s;
    border-radius: 10px;
}


.navItemOpen a {
    text-decoration: none;
    color: var( --secondaryColor);
}
.navItem a {
    text-decoration: none;
    color: var( --secondaryColor);
}
.navLine {
    position: absolute;
    bottom: 0;
    width: 131px;
    height: 12px;
    border-radius: 15px;
}
.dropDownBtn {
    position: relative;
    background-color: transparent;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
}
.dropDownBtnOpen {
    position: relative;
    background-color: var(--secondaryColor);
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    box-sizing: border-box;
    border-radius: 10px;
}


.dropDownMenu {
    position: absolute;
    top: 27px;
    left: -15px;
    min-width: 149px;
    
    font-size: 16px;
    display: flex;
    flex-direction: column;
    border-radius: 0px 10px 10px 10px;
    border: 1px solid #c2c2c2;
    overflow: hidden;
   
}
.dropDownMenu :last-child {
    border-bottom: none;
}
.dropDownItems {
    height: 40px;
    display: flex;
    align-items: center;
    font-weight: 500;
    border-bottom: 1px solid #c2c2c2;
    text-align: left;
    white-space: nowrap;
    padding: 0px 15px;
}
.dropDownItems:hover {
    background-color: var(--secondaryColor);
    color: white;
    transition: 0.5s;
}
.triangleUp {
    position: absolute;
    right: -16px;
    bottom: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 7px solid white;
}
.triangleDown {
    position: absolute;
    right: -16px;
    bottom: 5px;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid white;
}
.openColor {
    padding-left: 15px;
    padding-right: 25px;
    border-radius: 10px 10px 0px 0px;
    background-color: var(--secondaryColor);
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .header {
        flex-direction: column;
        height: 160px;
     }
     .logoBoxSmall {
        flex: 0.4;
        order: -3;
    }
    .nav {
        order: -1;
        flex: 0.4;
        display: flex;
       width: 100%;
    }
    .profileLogoLeft {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        margin-right: 40px;
        margin-top: 10px;
        height: 20px;
        order: -2;
        flex: 0.2;
    }
    .navItem {
        font-size: 17px;
        margin-top: 0;
        height: 30px;
     }
     .dropDownMenu {
        top: 24px;
        left: -15px;
       
    }
     .navLine {
        width: 100px;
        height: 10px;
    }
    .logoLeft {
        height: 70px;
        margin-top: 10px;
        margin-left: 0px;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .header {
        flex-direction: column;
        height: 160px;
     }
     .logoBoxSmall {
         flex: 0.4;
         order: -3;
     }
     .nav {
         order: -1;
         flex: 0.4;
         display: flex;
        width: 100%;
        padding: 0 3px;
     }
     .profileLogoLeft {
         width: 100%;
         display: flex;
         justify-content: flex-end;
         margin-right: 40px;
         margin-top: 10px;
         height: 20px;
         order: -2;
         flex: 0.2;
     }
     
     .navItem {
        font-size: 16px;
        margin-top: 0;
        height: 30px;
     }
     .navLine {
        width: 100px;
        height: 10px;
    }
    .dropDownMenu {
        top: 24px;
        left: -15px;
       
    }
     .logoLeft {
         height: 70px;
         margin-top: 10px;
         margin-left: 0px;
     }

 }
@media screen and (max-width:767px) {
    .headerLogoMiddle {
        height: 44px;
    }
    .language {
        position: absolute;
        border-radius: 0px 0px 6px 6px;
        top: 0px;
        right: 20px;
        height: 23px;
        width: 50px;
        box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
      }
      .languageIcon {
          display: none;
          height: 15px;
          margin-right: 5px;
      }
      .languageIcon svg {
          width: 18px;
          height: 16px;
          margin: 0 5px;  
          margin-top: 0px; 
      }
    .profileLogoMiddle {
        flex: 0.15;
        height: 44px;
        position: absolute;
        right: 20px;
    }
    .logoMiddle {
        height: 60px;
        margin-top:5px ; 
    }
    .header {
        flex-direction: row;
        height: 44px;
        justify-content: center;
     }
     .logoBoxSmall {
        flex: 0.7;
        color: white;
    }
    .logoBoxSmall a {
        color: white;
    }
    .logoBoxLarge {
        flex: 0.7;
        display: flex;
        justify-content: center;
        align-items: center;
        color: black;
    }
    .logoBoxLarge a {
        color: black;
    }
    .nav {
        background-color: white;
        box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
        position: fixed;
        top: 44px;
        left: 0px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
    }
    .nav button {
        color: black;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
    }
    .profileLogoLeft {
        position: absolute;
        right: 20px;
        height: 44px;
    }
    .logoLeft {
        height: 60px;
        margin-top: 10px;
        margin-left: 0px;
    }
    .navItem {
        box-sizing: border-box;
        color: black;
        height: 40px;
        position: relative;
        font-size: 14px;
        width: 100%;
        display: flex;
        align-items: flex-start;
        padding-left: 0px;
        padding-right: 0px;
        margin-top: 0px;
        min-width: 320px;
     }
     .navLine {
        display: none;
    }
    .invisibleBoxApper {
        flex: 0.15;
        position: absolute;
        left: 20px;
    }
    .triangleUp {
        right: 10px;
        width: 17px;
        height: 40px;
        bottom: 0px;
        border: none;
       background: url(../../assets/upNavSmall.svg) no-repeat center;
    }
    .triangleDown {
        right: 10px;
        width: 17px;
        height: 40px;
        bottom: 0px;
        border: none;
      background: url(../../assets/downNavSmall.svg) no-repeat center;
    }
    .dropDownMenu {
        box-sizing: border-box;
        position: absolute;
        top: 40px;
        left: 0px;
        min-width: 149px;
        width: 100%;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        border-radius: 0px;
        border: 1px solid #c2c2c2;
        z-index: 3;
        box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    }
    .dropDownBtn {
     padding-left: 20px;
    }
    .padding {
        padding-left: 20px;
    }
    .hamburgerBtn {
        position: absolute;
        left: 20px;
    }
    .openColor {
        border-radius: 0;
        background-color: var(--lightGrey);
    }

    .profileBtnWhite :nth-child(1) svg {
        fill: white;
    }
 
    .profileBtnBlack :nth-child(1) svg {
        fill: black;
    }
    .linkDisabled {
        pointer-events: none;
    }
    .navItem:hover {
        background-color: var(--secondaryColor);
        transition: .9s;
        border-radius: 0px;
       
    }
}