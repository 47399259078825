.searchBox {
  position: relative;
  color: var(--superDarkGrey);
}
.searchList {
  top: 70px;
  position: absolute;
  width: 100%;
  max-height: 300px;
  overflow: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--specialGrey);
  height: 300px;
  background-color: #fff;
  z-index: 10;
}
.searchList button {
  background-color: #fff;
  height: auto;
  border: none;
  padding: 5px;
  border-bottom: 1px dashed var(--specialGrey);
  cursor: pointer;
  text-align: start;
}

.searchList button:hover {
  background-color: var(--mainColor);
  color: white;
}
.alert {
  width: 19px;
  margin-right: 5px;
}
.box__passwordText {
  display: flex;
  align-items: center;
  list-style: none;
  font-size: 14px;
  padding: 5px 5px;
  margin-top: 8px;
  margin-bottom: 8px;
  line-height: 150%;
  background-color: #fff2f0;
  border: 1px solid #d9363e;
  border-radius: 5px;
  color: #d9363e;
}

.searchSelector {
  /* color: red; */
}

.searchSelectorDisabled {
  background-color: #ebebeb !important;
}
.subtitle {
  background-color: var(--lightGrey);
  color: var(--superDarkGrey);
  line-height: 150%;
  padding: 8px;
  margin-top: 6px;
  font-size: 14px;
  font-weight: 400;
}
.highLight {
  color: var(--mainColor);
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .searchList {
    top: 70px;
    max-height: 200px;
    border: 1px solid var(--specialGrey);
  }
}
@media screen and (max-width: 767px) {
  .searchList {
    top: 60px;
    max-height: 200px;
    border: 1px solid var(--specialGrey);
  }
}
