body.sipoa {
  --mainColor: #59c0ce;
  --secondaryColor: #2891ae;
  --thirdColor: #8fbe6a;
  --hoverColor: #0e6070;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#4cbdcc, #99ced2);
}
