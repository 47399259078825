.container {
  width: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}
.boxShape {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
}

.topBar {
  box-sizing: border-box;
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
}

.btnGroup {
  background-color: var(--backgroungColor);
  border-radius: 8px;
}

.btn {
  width: 140px;
  padding: 10px;
  display: inline-block;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  text-align: center;
}

.btn, .btn:active {
  color: var(--darkGrey);
}

.active {
  background-color: var(--thirdColor);
  color: white;
}
.exportPart {
  position: relative;
}
.ExportBtn {

  background: url(../../../assets/selectDownWhite.svg) no-repeat right center;
  background-color: var(--thirdColor);
  padding: 10px;
  width: 120px;
  font-size: 14px;
  text-align: left;
  border-radius: 10px;
  border: none;
  color: white;
  font-weight: 500;
  cursor: pointer;

}
.dropDown {
  width: 140px;
  top: 40px;
  position: absolute;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: #C6C6C6;
  box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
}
.dropDown button:last-child{
  border-bottom: none;
}
.dropDown button {
  display: block;
  width: 100%;
  border: none;
  text-align: left;
  list-style: none;
  padding: 8px 10px;
  background-color: #fff;
  border-bottom:1px solid #C6C6C6 ;
  cursor: pointer;
}
.dropDown button:hover{
  background-color: var(--backgroungColor);
}

/* content */
.content {
  display: flex;
  box-sizing: border-box;
  width: 100%;
}
.passTypeList {
  box-sizing: border-box;
  width: 15%;
  min-width: 200px;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
  margin-right: 10px;
}
.boxRight {
  width: 85%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}
.paymentTypeBox {
  /* height: 108px; */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.sectionTitle {
  font-weight: 600;
}
.timeBox {
  display: flex;
  width: 100%;
  margin-top: 10px;
}
.year {
  flex: 1;
  margin-right: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.day {
  flex: 1;
  padding: 20px;
  box-sizing: border-box;
}
.time {
  flex:1;
  margin-left: 10px;
  padding: 20px;
  box-sizing: border-box;
}
.overview {
  height: auto;
  width: 100%;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
}
.overview__title {
  font-weight: 400;
  font-size: 24px;
  padding-bottom: 20px;
  display: block;
  color: black;
  font-weight: 500;
}
.overview__boxLeft {
  margin: 20px;
  flex: .3;
}
.overview__boxRight {

  flex: .7;
  display: flex;
  flex-wrap: wrap;
}
.overview__bg {
  background-color: #F8F8F8;
  width: 400px;
  margin: 20px;
}
.overview__infos {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  padding-bottom: 0;
}
.overview__amount {
  padding-top: 10px;
  font-weight: 400;
  font-size: 40px;
}
.overview__filters {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 15px;
}
.right__chevron {
  margin-right: 10px;
}
.charts {
  padding-top: 30px;
  padding-bottom: 45px;
  padding-right: 15px;
  padding-left: 15px;
  margin-top: 10px;
   box-sizing: border-box;
}
.paymentOutsideBox {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.paymentFlexBox {
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  width: 98%;
}
.paymentTypeItem {
  padding: 0px 10px;
  width: 180px;
  margin: 10px;
  text-align: center;
  white-space: nowrap;
}
.paymentCheckbox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.weekFlexBox {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  min-width: 160px;
  justify-content: space-between;
  margin-top: 30px;
}
.checkBoxMargin {
  width: 40px;
  margin-top: 5px;
}
.listTitle {
  margin: 10px;
  color: var(--superDarkGrey);
  font-size:15px ;
  font-weight: 500;
}
.charts__box {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.chart__container {
  flex: 1;
}
.chart__header {
  margin-top: 15px;
  margin-bottom: 15px;
}
.chart__header__amount {
  font-weight: 400;
  font-size: 24px;
  margin-top: 15px;
}
.chart__header__title {
  display: flex;
  gap: 10px;
  align-items: center;
}
.chart {
  display: flex;
  width: 34vw;
}
.overview__flexBox {
  display: flex;
}
.overview__totalTitle {
  color:#63636B;
}
.passTypeItemBox {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
}
.passTypeItem {
  list-style: none;
  padding: 6px 10px; 
  margin:5px 5px 5px 0px;
  font-size: 14px;
  font-weight: 480;
  color: var(--superDarkGrey);
  background-color: #F2F2F2;
  border-radius: 5px;
}
.paymentTypeItemBox {
  display: flex;
  margin: 5px 5px 5px 0px;
}
.paymentTypeItem {
  list-style: none;
  color: var(--superDarkGrey);
  font-weight: 500;
  font-size: 16px;
  margin-right: 5px;
}
.space {
  margin: 0px 8px;
  color: #63636B;
}
.typeDateInfoBox {
  display: flex;
  flex-wrap: wrap;
}
.dateRangeBox {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-bottom: 6px;
}
.timeBoxes {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 5px;
  padding-left: 20px;
  padding-top: 2px;
  flex: 1;
  margin-left: 10px;
}
.allDayBox {
  display: flex;
  flex-direction: row;
  gap: 5px;
}
.timePickerBox {
  flex: 1;
  margin-top: 2px;
  flex-direction: column;
}
.yearTitle {
  margin-bottom: 8px;
  font-weight: 600;
}
.dateTitle {
  margin-bottom: 8px;
  margin-top: 10px;
}
.timeBoxMargin {
  margin-top: 10px;
  margin-bottom: 16px;
}
.radioBox {
  cursor: pointer;
  margin-right: 10px;
}
.timeTitle {
  margin-bottom: 4px;
}
.label {
  display: flex;
  align-items: center;
}
.svg {
  display: flex;
  align-items: center;
}
.svg svg {
  fill:var(--mainColor)
}
.filterTexts {
  font-size: 14px;
}
.timeRangeError {
  display: inline-block;
  margin-left: 10px;
  font-weight: 450;
  color: red;
}
@media screen and (min-width:1200px) and (max-width:1700px) {
  .chart {
    display: flex;
    width: 45vw;
  }
}
@media screen and (min-width:992px) and (max-width:1199px) {
  
  .charts__box {
    flex-wrap: nowrap;
  }
  .boxRight {
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .passTypeList {
    width: 100%;
    height: auto;
    border-radius: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .overview {
    flex-direction: column;
  }
  .overview__title {
    font-size: 20px;
  }
  .overview__boxLeft {
    margin: 10px ;
    flex: .3;
  }
  .overview__boxRight {
  
    flex: .7;
    display: flex;
  }
  .overview__bg {
    background-color: #F8F8F8;
    width: 100%;
    margin: 10px;
  }
  .charts__box {
    display: flex;
    flex-direction: column;
  }
  .chart {
    display: flex;
    width: 100%;
  }
  .overview__amount {
    padding-top: 10px;
    font-weight: 400;
    font-size: 30px;
  }
  .boxShape {
    border-radius: 0;
  }
  .timeBox {
    flex-direction: column;
    align-items: center;
  }
  .year {
    width: 100%;
    height: auto;
    margin-right: 0px;
  }
  .day {
    width: 100%;
    height: auto;
  }
  .time {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
  .paymentFlexBox {
    width: 100%;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .charts__box {
    flex-wrap: nowrap;
  }
  .boxRight {
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .passTypeList {
    width: 100%;
    height: auto;
    border-radius: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .overview {
    flex-direction: column;
  }
  .overview__title {
    font-size: 20px;
  }
  .overview__boxLeft {
    margin: 10px ;
    flex: .3;
  }
  .overview__boxRight {
  
    flex: .7;
    display: flex;
  }
  .overview__bg {
    background-color: #F8F8F8;
    width: 100%;
    margin: 10px;
  }
  .charts__box {
    display: flex;
    flex-direction: column;
  }
  .chart {
    display: flex;
    width: 100%;
  }
  .overview__amount {
    padding-top: 10px;
    font-weight: 400;
    font-size: 30px;
  }
  .boxShape {
    border-radius: 0;
  }
  .timeBox {
    flex-direction: column;
    align-items: center;
  }
  .year {
    width: 100%;
    height: auto;
    margin-right: 0px;
  }
  .day {
    width: 100%;
    height: auto;
  }
  .time {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
  .paymentFlexBox {
    width: 100%;
  }
}
@media screen and (max-width:767px) {
  .charts__box {
    flex-wrap: nowrap;
  }
  .container {
    width: 100%;
    padding-right: 0px;
  }
  .topBar {
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    padding: 8px;
    border-radius: 0px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin-top: 10px;
  }
  .btnGroup {
    background-color: var(--backgroungColor);
    border-radius: 8px;
    width:240px ;
    margin-bottom: 10px;
  }
  
  .btn {
    padding: 10px 0px;
    width: 120px;
    font-size: 13px;
  }
  .ExportBtn {
    font-size: 13px;
  }
  .boxRight {
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
  }
  .passTypeList {
    width: 100%;
    height: auto;
    border-radius: 0px;
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .overview {
    flex-direction: column;
  }
  .overview__title {
    font-size: 20px;
  }
  .overview__boxLeft {
    margin: 10px ;
    flex: .3;
  }
  .overview__boxRight {
  
    flex: .7;
    display: flex;
  }
  .overview__bg {
    background-color: #F8F8F8;
    width: 100%;
    margin: 10px;
  }
  .charts__box {
    display: flex;
    flex-direction: column;
  }
  .chart {
    display: flex;
    width: 100%;
  }
  .overview__amount {
    padding-top: 10px;
    font-weight: 400;
    font-size: 30px;
  }
  .boxShape {
    border-radius: 0;
  }
  .timeBox {
    flex-direction: column;
    align-items: center;
  }
  .year {
    width: 100%;
    height: auto;
    margin-right: 0px;
  }
  .day {
    width: 100%;
    height: auto;
  }
  .time {
    width: 100%;
    height: auto;
    margin-left: 0px;
  }
  .paymentFlexBox {
    width: 100%;
  }
  .paymentFlexBox {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 98%;
  }
  .paymentTypeItem {
    padding: 0px 10px;
    width: 180px;
    margin: 10px;
    text-align: left;
    white-space: nowrap;
  }
  
  

  
}