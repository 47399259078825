.infoSectionBox {
    width: 100%;
}
.sectionHeader {
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
}
.editBtnBox {
    font-size: 14px;
}
.title {
    font-size: 14px;
    font-weight: 500;
}
.infoBox {
    width: 100%;
    display: flex;
}
.listBox {
    width: 100%;
    margin-top: 10px;
}
.listItemBox {
  width: 100%;
  flex: 1;
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
}
.listTitle {
    font-weight: 500;
    justify-content: flex-start;
    text-align: start;
}
.listContent {
    text-align: end;
    list-style: none;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
    .listTitle {
        font-weight: 500;
        font-size: 14px;
    }
    .listContent {
        font-size: 14px;
    }
}

