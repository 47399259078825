.box {
  margin-top: 132px;
  background-color: #fff;
}
.header {
  height: 65px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 20px;
}
.topLineRight {
  display: flex;
  align-items: center;
}
.title {
  margin-left: 20px;
}
.gridStatusBox {
    margin-top: 9px;
    border-radius: 25px;
    display: flex;
    justify-content: center;
    font-weight: 700;
    align-items: center;
    height: 25px;
  }
  .radiusBox {
    box-sizing: border-box;
    padding: 0px 20px;
    margin-top: 10px;
    width: 100%;
    height: calc(100vh - 250px);
    border-radius: 0px;
    /* overflow: scroll;
    scroll-behavior: smooth;
    overscroll-behavior: contain; */
    background-color: #fff;
  }
  .active {
    background-color: #15a021;
    color: white;
  }
  .readyToScan {
    background-color: #9ccc8b;
    color: white;
  }
  .incomplete {
    background-color: #c4c4c4;
    color: white;
  }
  .expired {
    background-color: #a01515;
    color: white;
  }
  .refunded {
    background-color: #e9a41e;
    color: white;
  }
  .incompleteRentalCar {
    background-color: var(--darkGrey);
    color: white;
  }
  .item {
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding: 0px 10px;
    text-transform: capitalize;
  }
  .topLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
  }
  .btnExport {
    display: flex;
    justify-content: center;
    width: 170px;
    height: 36px;
    background: #ffffff;
    border: 1px solid var(--mainColor);
    color: var(--mainColor);
    font-weight: 500;
    box-shadow: 0px 1px 5px 2px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    align-items: center;
    margin-left: 20px;
  }
  .btnExport:hover {
    background-color: var(--mainColor);
    color: white;
    transition: 0.4s;
  }
  .gridSearch {
    border-radius: 5px;
    height: 36px;
    background-color: #f5f5f5;
    border: 1px solid #c2c2c2;
    font-size: 14px;
    outline-style: none;
    line-height: 36px;
    width: 20%;
    padding: 10px;
    min-width: 200px
  }
  .textSearch input {
    width: '25%';
    padding: '10px 20px';
  }
  .bulkSelectBox {
    padding: 8px 20px 8px 10px;
    border: none;
    appearance: none;
    font-size: 14px;
    font-weight: 700;
    outline-style: none;
    color: black;
    background-color: transparent;
    border-radius: 4px;
    cursor: pointer;
    /* background: url(../../assets/dropDownSmall.svg) no-repeat right center; */
  }
  .bulkSelectBox svg {
    stroke: var(--mainColor);
  }
  .bulkSelectBox:hover {
    /* background: url(../../assets/dropDownSmallWhite.svg) no-repeat right center; */
    background-color: var(--mainColor);
    color: white;
  }
  .actionBtn {
   border: none;
   color: var(--mainColor);
   background-color: transparent;
   font-weight: 500;
   cursor: pointer;
   display: flex;
  }
  .actionBox {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    padding-top: 12px;
  }
  .actionBtn svg {
    margin-top: 1px;
    min-width: 14px;
    stroke: var(--mainColor);
  }
  .menuContainer {
    width: 240px;
    background-color: #F8F8F8;
    box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08);
    border: 1px solid #CCCFD4;
  }
  .menuItem {
    height: 30px;
    border-bottom: 1px solid #dee2ea;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #333333;
    font-size: 14px;
  }
  .menuItem:hover {
    background-color: #dee2ea;
  }
  .menuItem a {
    display: block;
    width: 100%;
    padding: 8px;
  }
  .menuItem button {
    padding: 8px;
    margin-left: 16px;
  }
  .menuItem a button {
    padding: 0px;
  }
  .itemText {
    margin-left: 16px;
  }
  .tableLink {
    color: var(--mainColor);
  }
  .tableLink:visited {
    color: var(--mainColor);
  }
  .tableLink:hover {
    color: var(--secondaryColor);
  }
  .selectBox {
    margin: 0 5px;
    width: 77px;
    border-radius: 2px;
    border: 1px solid #bdbdbd;
    outline-style: none;
    cursor: pointer;
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../assets/traingleDownSmall.svg) no-repeat center right;
  }
  .selectBox option {
    font-size: 14px;
    font-weight: 500;
  }
  
  @media screen and (min-width: 1200px) {

  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
    .box {
      margin-top: 180px;
    }
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
    .box {
      margin-top: 180px;
    }
  }
  @media screen and (max-width: 767px) {
    .header {
      height: 40px;
    }
    .title {
      font-size: 20px;
    }
    .box {
      margin-top: 44px;
    }
    .topLine {
      display: flex;
      flex-wrap: wrap;
    }
    .btnExport {
      width: 130px;
      font-size: 14px;
    }
  }
