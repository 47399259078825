body.debordieu {
  --mainColor: #013366;
  --secondaryColor: #02203F;
  --thirdColor: #D35840;
  --hoverColor: #02203F;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#025586, #0077BE);
}
