.form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .infoBox {
    width: 40%;
    margin-top: 40px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    border-radius: 20px;
  }
  .paddingBox{
    padding: 0px 20px 20px 20px;
  }
  .header {
    width: 100%;
    background-color: var(--superDarkGrey);
    height: 44px;
    border-radius: 20px 20px 0px 0px;
    display: flex;
    align-items: center;
    color: white;
  }
  .role {
    font-size: 14px;
    font-weight: 400;
    color: var(--darkGrey);
    margin-bottom: 8px;
  }
  .roleItem {
    list-style: none;
    
  }
  .roleItem li {
    background-color: #f2f2f2;
    margin: 5px 0px;
    padding:0px 10px;
    height: 34px;
    border-radius: 20px;
    display: flex;
    align-items: center;
  }
  
  .label {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 500;
    color: var(--superDarkGrey);
  }
  .input {
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid var(--specialGrey);
    height: 26px;
    overflow: hidden;
    width: 100%;
    outline-style: none;
    margin-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    text-indent: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
  }
  
  .inputBox label {
    color: var(--darkGrey);
  }
  .inputBox  {
    margin: 10px 0px;
  }
  .title {
    font-size: 16px;
    font-weight: 500;
    margin-left: 20px;
  }
  .personalInfoBox {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .btn {
    width: 200px;
    margin-top: 40px;
  }
  @media screen and (min-width:1200px) {
    
  }
  @media screen and (min-width:992px) and (max-width:1199px) {
    .infoBox {
        width: 60%;
    }
  }
  @media screen and (min-width:768px) and (max-width:991px) {
    .infoBox {
        width: 60%;
    }
  }
  @media screen and (max-width:767px) {
    .infoBox {
        width: 99%;
        margin-top: 20px;
    }
    .note {
        font-size: 14px;
    }
  }