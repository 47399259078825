.moreDetailsBox {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 1000px;
}
.radiusBox {
  margin-top: 40px;
  width: 98%;
  border-radius: 20px;
  box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
  overflow-x: auto;
}
.table {
  min-width: auto;
}
.tHead {
  width: 100%;
}
.tableHeader {
  width: 100%;
  height:54px ;
  font-size: 16px;
  font-weight: 400;
}
.tableHeader th {
  font-weight: 500;
  min-width: 100px;
  padding: 10px;
  text-align: center;
}
.tBody td {
  text-align: center;
  height: 54px;
}
.pairBox {
  padding: 20px;
}
.pairLabel {
  margin-right: 20px;
}
.pairValue {
  color: var(--darkGrey);
}
.passStatusActive {
  border-radius: 20px;
  padding: 5px 15.66px;
  background-color: var(--green);
}
.passStatusExpired {
  border-radius: 20px;
  padding: 5px 11.115px;
  background-color: var(--red);
}
.passStatusUpcomming {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: rgb(118, 209, 118);
}
.passStatusInactive {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--lightGrey);
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .table {
    min-width: 900px;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
    
}
@media screen and (max-width:767px) {
  .radiusBox {
    margin-top: 40px;
    width: 100%;
    border-radius: 0px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    overflow-x: auto;
  }
  .table {
    min-width: 900px;
  }
}
