
/* .infoBox {
    position: relative;
} */
.dropDownBtn  {
    display: flex;
    justify-content: center;
    align-items: center;
}

.buttonSelectDown button {
  background: url(../../assets/selectDown.svg) no-repeat center right;
  width: 30px;
  height: 30px;
  border: none;
  text-align: left;
}
.buttonSelectUp button {
  background: url(../../assets/selectUp.svg) no-repeat center right;
  width: 30px;
  height: 30px;
  border: none;
  text-align: left;
  background-color: var(--specialGrey);
  border-radius: 5px 5px 5px 5px;
}
.dropDownBox {
    position: absolute;
    padding-right: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    z-index: 1;
    top: 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: min-content;
    min-width: 300px;
}
.title {
    margin: 6px 10px;
}
.dropDownBox ul {
    margin: 6px 10px;
    text-align: left;
}
.dropDownBox li {
    list-style: none;
    margin: 5px 0px;   
}
.title {
    color:var(--darkGrey)
}
.info {
    font-weight: 500;
}