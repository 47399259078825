.box {
    width: 80%;
}
.alertBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 60px;
}
.alertImg {
    width: 54px;
    height: 54px;
    margin-bottom: 20px;
}
.text {
    font-weight: 500;
    text-align: center;
    margin-top: 10px;
}
.btnBoxIncomplete {
    width: 100%;
    display: flex;
    justify-content: center;
}


.confirm {
    width: 254px;
    height: 43px;
    background-color: var(--mainColor);
    border-radius: 10px;
    color: white;
    cursor: pointer;
    border: none;
}
.confirm:hover {
    background-color: var(--hoverColor);
    transition: .4s;
}
.wrapper {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    padding-top: 2em;
}
.image {
    width: 38%;
    box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
    border-radius: 10px;
    transform: translateX(70.5px);
}
.btnBox {
    transform: translateX(70.5px);
}
.spacer {
    width: 1em;
}
.actionInterface {
    width: 30%;

}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
    .image {
        width: 45%;
    }
}
@media screen and (max-width:767px) {
    .btnBoxIncomplete {
        flex-direction: column;
        align-items: center;
    }
    .confirm {
        width: 200px;
        height: 35px;
    }
    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 1em;
    }
    .image {
        width: 100%;
        box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
        border-radius: 0px;
        transform: translateX(0px);
    }
    .btnBox {
        transform: translateX(0px);
        margin-top: 10px;
    }
}
