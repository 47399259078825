
.standardLabel {
  font-size: 14px;
  font-weight: 500px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; */
}

.elemWrapper {
  background-color: rgba(0, 0, 0, 0.08);
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
}

.dropDownButton {
  background-color: white;
  outline: none;
  border-style: solid;
  border-width: 1px;
  border-color: var(--specialGrey);
  padding: 4px 10px;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.08);
  cursor: pointer;
}
.dropDownButton:hover {
  /* background-color: var(--specialGrey); */
  color: var(--mainColor)
}

.menuContainer {
  width: 240px;
  background-color: #F8F8F8;
  box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.08);
  border: 1px solid #CCCFD4;
  z-index: 3000;
}
.menuItem {
  height: 30px;
  border-bottom: 1px solid #dee2ea;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #333333;
  font-size: 14px;
  margin-bottom: 0px;
  padding-left: 5px;
}
.menuItem:hover {
  background-color: #dee2ea;
}
.menuItem label {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.menuItem button {
  width: 100%;
  height: 100%;
  background-color: inherit;
  outline: none;
  border-width: 0px;
  text-align: left;
}
.itemText {
  margin-left: 16px;
}
.anchor {
  color: var(--mainColor);
  text-decoration: underline;
}
.anchor:hover {
  color: black;
}