.box {
  width: 80%;
}
.alertBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 60px;
}
.alertImg {
  width: 54px;
  height: 54px;
  margin-bottom: 20px;
}
.text {
  font-weight: 500;
  text-align: center;
}
.btnBox {
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirm {
  width: 254px;
  height: 43px;
  background-color: var(--mainColor);
  border-radius: 10px;
  color: white;
  cursor: pointer;
  border: none;
}
.confirm:hover {
  background-color: var(--hoverColor);
  transition: .4s;
}
.title {
  font-weight: 500;
  font-size: 18px;
}
.checkedIcon {
  margin: 30px;
}
.checkedIcon svg {
  height: 40px;
  width: 40px;
}
@media screen and (min-width:1200px) {
  
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
  
}
@media screen and (max-width:767px) {
  .btnBox {
      flex-direction: column;
      align-items: center;
  }
}