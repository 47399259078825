.passStatusSetter {
  border-style: none;
  border-radius: 20px;
  padding: 5px 10px;
  background-color: gray;
  color: white;
  appearance: none;
  background: url(../../assets/selectDownWhite.svg) no-repeat right;
  padding-right: 30px;
}
.passStatusSetter[data-color="red"] {
  background-color: var(--red);
}
.passStatusSetter[data-color="green"] {
  background-color: var(--green);
}
.passStatusSetter[data-color="grey"] {
  background-color: grey;
}
.passStatusSetter[data-color="darkGrey"] {
  background-color: var(--darkGrey);
}
.passStatusSetter[data-color="lightGreen"] {
  background-color: rgb(118, 209, 118);
}
.passStatusSetter[data-color="orange"] {
  background-color: #E9A41E;
}