.infoFormBox {
  width: 80%;
}
.header {
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 500;
  position: relative;
  padding: 5px 15px;
}
.inputShortBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.inputShort {
  width: 48%;
  margin-top: 10px;
}
.standardLabel {
  font-size: 14px;
  font-weight: 500px;
  /* display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; */
}
.standardLabel input[type="file"] {
  font-size: 16px;
  margin-top: 8px;
}
.standardLabel input::file-selector-button {
  color: white;
  background-color: var(--mainColor);
  outline: none;
  border-style: solid;
  border-width: 0px;
  border-radius: 6px;
  padding: 2px 5px;
  cursor: pointer;
}
.standardLabel input::file-selector-button:hover {
  background-color: var(--hoverColor);
}

.inputShort select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background: url(../../assets/dropDown.svg) no-repeat scroll right center
    transparent;
  padding-right: 23px;
  text-indent: 5px;
  cursor: pointer;
}
.inputLong {
  margin-top: 16px;
  width: 100%;
}
.numberBox {
  display: flex;
  align-items: center;
  margin-top: 30px;
  justify-content: space-between;
}
.btnDown {
  transform: scaleX(1.3);
}
.btnRemove {
  font-size: 12px;
}
.tableContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
}
.tableBox {
  width: 100%;
  margin-top: 20px;
}
.tdBox {
  width: 52%;
  font-weight: 500;
  font-size: 14px;
}
.checkBox {
  width: 22px;
  height: 22px;
  border: 2px solid var(--specialGrey);
  outline-style: none;
  -webkit-appearance:none;
  cursor: pointer;
  align-items: center;
}
.checkBox:checked {
  background-color: var(--mainColor);
}
.addonContainer {
  display: flex;
  align-items: center;
  margin-top: 12px;
}
.addonLabel { 
  margin-left: 12px;
  font-size: 14px;
  font-weight: bold;
}
.view {
  margin-left: 3%;
}
.alert {
  font-size: 14px;
  padding: 5px 5px;
  margin-top: 10px;
  line-height: 150%;
}
.leftPart {
  display: flex;
  align-items: center;
}
.dateInput {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 15px;
  padding-right: 5px;
  background: #ffffff;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dateInput svg {
  margin: 0;
  width: 20px;
  height: 20px;
}

.dateValue {
  height: 34px;
  width: 100%;
  margin-top: 8px;
  padding-top: 4px;
}
.searchSelect input {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 34px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 17px;
  text-indent: 4px;
  color: var(--mediumGrey);
}
.incompleteWarning {
  background-color: #efefef;
  padding: 0.5rem;
  border: 1px solid var(--mediumGrey);
  border-radius: 5px;
  line-height: 130%;
  color: #333333;
}
.completeBox {
  margin-top: 12px;
}
.completeBox label {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.completeBox input {
  margin-right: 12px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
}
@media screen and (min-width: 768px) and (max-width: 991px) {
}
@media screen and (max-width: 767px) {
  .inputShortBox {
    flex-direction: column;
  }
  .inputShort {
    width: 100%;
    margin-top: 10px;
  }
  .btnDown {
    margin-left: 3%;
    margin-right: 2%;
  }
  .infoFormBox {
    width: 100%;
  }
  .header {
    font-size: 12px;
  }
  .btnRemove {
    position: absolute;
    right: 3%;
  }
  .inputShort select {
    height: 28px;
  }
}
