* {
    margin: 0;
    padding: 0;
   }
.active {
    background-color: var(--thirdColor);
    width: 100%;
}
.box {
    position: relative;
    box-sizing: border-box;
    margin-top: 112px;
    display: flex;
    justify-content: flex-end;
}
.infoBox {
    box-sizing: border-box;
    width: calc(100% - 243px);
    display: flex;
}
.invisiableBox {
    box-sizing: border-box;
    height: 1000px;
    min-width: 243px;
}
.invisiableBoxSecurity {
    box-sizing: border-box;
    height: 1000px;
    min-width: 223px;
}
.invisableBoxShort {
    box-sizing: border-box;
    min-width: 43px;
    height: 1000px;
}
.invisableBoxShortSecurity {
    box-sizing: border-box;
    min-width: 23px;
    height: 1000px;
}
.subNav {
    position: relative;
    position: fixed;
    left: -200px;
    display: flex;
    flex-direction: column;
    height: 100%;
   width: 200px;
    z-index: 4;
    animation-name : move_right;
    animation-duration : 1.2s;
    animation-iteration-count : 1;
    animation-fill-mode : forwards;
   
}
.subNavShort {
    position: relative;
    position: fixed;
    left: 0px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 200px;
    z-index: 4;
    animation-name : move_left ;
    animation-duration : 1.2s;  
    animation-iteration-count : 1;
    animation-fill-mode : forwards;
}
.subNav a {
   color: white;
   font-weight: 500;
   margin-left: 0;
}
.subNavShort a {
    color: white;
    margin-left: 0;
 }
.drawerBar {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 23px;
    left: 0px;
    cursor: pointer;
    z-index: 4;
    animation-name : move_right;
    animation-duration : 1.2s;
    animation-iteration-count : 1;
    animation-fill-mode : forwards;
}
.drawerBarLeft {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 23px;
    left: 200px;
    cursor: pointer;
    z-index: 4;
    animation-name : move_left;
    animation-duration : 1.2s;  
    animation-iteration-count : 1;
    animation-fill-mode : forwards;
}
.btnBox {
    background-color: transparent;
    border: none;
    outline-style: none;
    width: 23px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.triangleLeft {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-right: 6px solid white;
    border-bottom: 5px solid transparent;
    cursor: pointer;
}
.triangleRight {
    width: 0;
    height: 0;
    border-top: 5px solid transparent;
    border-left: 6px solid white;
    border-bottom: 5px solid transparent;
    cursor: pointer;
}

.subNavBtn {
    height: 70px;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding-left: 10%;
    text-align: left;
}
.subNavBtn:hover {
    background-color: var(--thirdColor);
    cursor: pointer;
    transition: .9s;
}
.btnImg svg {
    fill: white;
    margin-right: 5px;
}

.subHeader {
    position: relative;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 3;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
}
.subHeaderWithoutNav {
    position: relative;
    position: fixed;
    width: 100%;
    height: 70px;
    z-index: 3;
    display: flex;
    align-items: center;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
}
.title {
    margin-left: 0px;
    font-size: 32px;
    font-weight: 400;
}
.icon {
    margin-left: 240px;
    animation: iconRight 1s  ; 
}

.icon svg {
    fill: var(--mainColor);
    stroke: var(--mainColor);
    stroke-width: 0.2px;
    height: 60px;
    width: 60px;
}

.iconLeft {
    margin-left: 43px;
    animation: iconLeft 1s ; 
}
.iconLeft svg {
    fill: var(--mainColor);
    stroke: var(--mainColor);
    stroke-width: 0.2px;
    height: 60px;
    width: 60px;
}
.infoBox {
    margin-top: 90px;
    animation: move_rightInfo 1s  ;
}
.infoBoxWithoutNav {
    animation: move_leftInfo 1s  ;  
    width: calc(100% - 43px);
    /* padding-left: 0px; */
    margin-top: 90px;
    
}
.iconSubNav {
    width: 45px;
}
.addUserBox {
    position: absolute;
    right: 20px;
}
@keyframes move_rightInfo {

    from {
    width: calc(100% - 43px);
    }
    to {
    width: calc(100% - 243px);
    }   
}
@keyframes move_leftInfo {

    from {
    width: calc(100% - 243px);
    }
    to {
    width: calc(100% - 43px);
    }   
}
@keyframes move_right {

    from {
    opacity: 1; 
    }
    to {
    opacity: 1;
    transform: translateX(200px);
    }   
}
 @keyframes move_left{
    from {
    opacity: 1;
    }
    to {
    opacity: 1;
    transform: translateX(-200px);
    
    }
        
}
@keyframes iconRight {
    from {
        margin-left: 43px;
    } 
    to {
        margin-left: 240px;
    }
}
@keyframes iconLeft {
    from {
        margin-left: 240px;
    } 
    to {
        margin-left: 43px;
    }
}


@media screen and (min-width:1920px) {
    .icon {
        margin-left: 240px;
        width: 70px;
    }
}
@media screen and (min-width:1200px) and (max-width:1919px) {
 
}
@media screen and (min-width:992px) and (max-width:1199px) {
  
    .box {
        margin-top: 160px;
    }
    .subNav {
        position: relative;
        position: fixed;
        left: 0px;
        top: 90px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 80px;
        width: 100%;
        min-width: 320px;
        max-width: 100%;
        animation-name : move_down;
        animation-duration : 1s;
        animation-iteration-count : 1;
        animation-fill-mode : forwards;
    }
    .subNavShort {
        position: relative;
        position: fixed;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 80px;
        width: 100%;
        min-width: 320px;
        max-width: 100%;
        left: 0px;
        animation-name : move_up;
        animation-duration : 1s;
        animation-iteration-count : 1;
        animation-fill-mode : forwards;
    }
    .active {
        background-color: var(--thirdColor);
        width: 140px;
    }
    .subNavBtn {
        width: 140px;
        height: 100%;
        align-items: center;
        font-size: 14px;
        padding-left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .subNavBtn a {
        text-align: center;
    }
    .btnImg {
        margin-top: 10px;
    }
    .btnImg svg {
        fill: white;
        width: 35px;
        height: 35px;
        margin-right: 0px;
    }
  
    .subHeader {
        position: fixed;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:80px ;
        min-width: 320px;
        animation: move_downSubheader 1s;
    }
    .subHeaderWithoutNav {
        position: fixed;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:10px ;
        min-width: 320px;
        animation: up_downSubheader 1s;
    }
    .title {
        margin-left: 0px;
        font-size: 20px;
        font-weight: 400;
    }
    .icon svg {
        fill: var(--mainColor);
        height: 50px;
        width: 50px;
    }
    .iconLeft svg {
        fill: var(--mainColor);
        height: 50px;
        width: 50px;
    }
    .icon {
        margin-left: 0px;
        animation-name: none;
      
    }
    .iconLeft {
        margin-left: 0px;
        animation-name: none;

    }
    .invisiableBox {
      display: none;
    }
    .invisiableBoxSecurity {
       display: none;
    }
    .invisableBoxShort {
      display: none;
     }
     .invisableBoxShortSecurity {
       display: none;
    }
    .drawerBar {
        left: 0px;
        display: flex;
        height: 10px;
        width: 100%;
        animation-name : stand_still;
    }
    .drawerBarLeft {
        left: 0px;
        display: flex;
        height: 10px;
        width: 100%;
        animation-name : stand_still;
    }
    .iconSubNav {
        width: 40px;
        margin-top: 10px;
    }
    .infoBox {
        margin-top: 163px;
        padding-left: 20px;
        width: 100%;
        animation: move_downInfo 1s  ;
    }
    .infoBoxWithoutNav {
        margin-top: 93px;
        padding-left: 20px;
        width: calc(100% - 20px);
        animation: move_upInfo 1s  ;
    }
    .btnBox {
        background-color: transparent;
        border: none;
        outline-style: none;
        width: 100%;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .triangleLeft {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .triangleRight {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        cursor: pointer;
        margin-top: 5px;
    }
    @keyframes move_up {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(-70px);
        
        }
    }
    @keyframes move_down {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(70px);
        
        }
    }
    @keyframes stand_still {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(0px);
        
        }
    }


    @keyframes move_downInfo {

        from {
        margin-top: 93px;
        }
        to {
        margin-top:  163px;
        }   
    }
    @keyframes move_upInfo {
    
        from {
            margin-top: 163px;
        }
        to {
            margin-top: 93px;
        }   
    }

    @keyframes move_downSubheader {
        from {
            margin-top: 10px ;
        }
        to {
            margin-top: 80px ;
        }
    }

    @keyframes up_downSubheader {
        from {
            margin-top: 80px ;
        }
        to {
            margin-top: 10px ;
        }
    }
  
}
@media screen and (min-width:768px) and (max-width:991px) {
    .box {
        margin-top: 160px;
    }
    .subNav {
        position: relative;
        position: fixed;
        left: 0;
        top: 90px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 80px;
        width: 100%;
        min-width: 320px;
        max-width: 100%;
        animation-name : move_down;
        animation-duration : 1s;
        animation-iteration-count : 1;
        animation-fill-mode : forwards;
    }
    .subNavShort {
        position: relative;
        position: fixed;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        height: 80px;
        width: 100%;
        min-width: 320px;
        max-width: 100%;
        animation-name : move_up;
        animation-duration : 1s;
        animation-iteration-count : 1;
        animation-fill-mode : forwards;
    }
    .active {
        background-color: var(--thirdColor);
        width: 90px;
    }
    .subNavBtn {
        width: 90px;
        height: 100%;
        align-items: center;
        font-size: 14px;
        padding-left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
    .subNavBtn a {
        text-align: center;
    }
    .btnImg {
        margin-top: 10px;
    }
    .btnImg svg {
        fill: white;
        width: 35px;
        height: 35px;
        margin-right: 0px;
    }
    .subHeader {
        position: fixed;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:80px ;
        min-width: 320px;
        animation: move_downSubheader 1s;
    }
    .subHeaderWithoutNav {
        position: fixed;
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top:10px ;
        min-width: 320px;
        animation: up_downSubheader 1s;
    }
    .title {
        margin-left: 0px;
        font-size: 20px;
        font-weight: 400;
    }
    .icon svg {
        fill: var(--mainColor);
        height: 50px;
        width: 50px;
    }
    .iconLeft svg {
        fill: var(--mainColor);
        height: 50px;
        width: 50px;
    }
    .icon {
        margin-left: 0px;
        animation-name: none;
    }
    .iconLeft {
        margin-left: 0px;
        animation-name: none;
    }
    .invisiableBox {
      display: none;
    }
    .invisiableBoxSecurity {
        display: none;
     }
    .invisableBoxShort {
        display: none;
     }
     .invisableBoxShortSecurity {
        display: none;
     }
    .drawerBar {
        left: 0;
        display: flex;
        height: 10px;
        width: 100%;
        animation-name : stand_still;
    }
    .drawerBarLeft {
        left: 0;
        display: flex;
        height: 10px;
        width: 100%;
        animation-name : stand_still;
    }
    .iconSubNav {
        width: 40px;
        margin-top: 10px;
    }
    .infoBox {
        margin-top: 163px;
        padding-left: 20px;
        width: 100%;
        animation: move_downInfo 1s  ;
    }
    .infoBoxWithoutNav {
        margin-top: 93px;
        padding-left: 20px;
        width: calc(100% - 20px);
        animation: move_upInfo 1s  ;
    }
    .btnBox {
        background-color: transparent;
        border: none;
        outline-style: none;
        width: 100%;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .triangleLeft {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .triangleRight {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        cursor: pointer;
        margin-top: 5px;
    }
    @keyframes move_up {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(-70px);
        
        }
    }
    @keyframes move_down {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(70px);
        
        }
    }
    @keyframes stand_still {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(0px);
        
        }
    }
    @keyframes move_downInfo {

        from {
        margin-top: 93px;
        }
        to {
        margin-top:  163px;
        }   
    }
    @keyframes move_upInfo {
    
        from {
            margin-top: 163px;
        }
        to {
            margin-top: 93px;
        }   
    }

    @keyframes move_downSubheader {
        from {
            margin-top: 10px ;
        }
        to {
            margin-top: 80px ;
        }
    }

    @keyframes up_downSubheader {
        from {
            margin-top: 80px ;
        }
        to {
            margin-top: 10px ;
        }
    }
  

}
@media screen and (max-width:767px) {
    .box {
        margin-top: 44px;
    }
    
    .subNav {
        box-sizing: border-box;
        position: relative;
        position: fixed;
        left: 0;
        top: -24px;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 80px;
        width: 100%;
        min-width: 320px;
        max-width: 100%;
        padding: 0px 5px;
        animation-name : move_down;
        animation-duration : 1s;
        animation-iteration-count : 1;
        animation-fill-mode : forwards;
        overflow: auto;
    }
    .subNavShort {
        box-sizing: border-box;
        position: relative;
        position: fixed;
        left: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        height: 80px;
        width: 100%;
        min-width: 320px;
        max-width: 100%;
        padding: 0px 5px;
        animation-name : move_up;
        animation-duration : 1s;
        animation-iteration-count : 1;
        animation-fill-mode : forwards;
        overflow: auto;
    }
    .active {
        background-color: var(--thirdColor);
        width: 120px;
        height: 65px;
    }
    .subNavBtn {
        width: 120px;
        box-sizing: border-box;
        padding-top: 12px;
        align-items: center;
        font-size: 12px;
        padding-left: 0;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        height: 65px;
        text-align: center;
    }
    .subNavBtn:hover {
        height: 80px;
    }
    .subHeaderWithoutNav {
        position: relative;
        position: fixed;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top:10px ;
        min-width: 320px;
        animation: up_downSubheader 1s;
    }
    .subNavBtn a {
        text-align: center;
    }
    .btnImg {
        margin-top: 0px;
    }
    .btnImg svg {
        fill: white;
        width: 30px;
        height: 30px;
        margin-right: 0px;
    }
    .iconSubNav {
        width: 30px;
    }
    .subHeader {
        position: relative;
        position: fixed;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: left;
        align-items: center;
        margin-top:80px ;
        min-width: 320px;
        animation: move_downSubheader 1s;
    }
    .title {
        margin-left: 0px;
        font-size: 18px;
        font-weight: 400;
    }
    .icon svg {
        fill: var(--mainColor);
        height: 32px;
        width: 32px;
    }
    .iconLeft svg {
        fill: var(--mainColor);
        height: 32px;
        width: 32px;
    }
    .icon {
        margin-left: 0px;
        animation: none;
    }
    .iconLeft {
        margin-left: 0px;
        animation: none;
    }
    .invisiableBox {
        display: none;
      }
      .invisiableBoxSecurity {
        display: none;
     }
      .invisableBoxShort {
        display: none;
     }
     .invisableBoxShortSecurity {
        display: none;
     }
      .infoBox {
        margin-top: 130px;
        width: 100%;
        animation: move_downInfo 1s  ;
    }
    .infoBoxWithoutNav {
        margin-top: 70px;
        padding-left: 0px;
        width: 100%;
        animation: move_upInfo 1s  ;
    }
    .drawerBar {
        left: 0;
        display: flex;
        height: 10px;
        width: 100%;
        min-width: 320px;
        animation-name : stand_still;
    }
    .drawerBarLeft {
        left: 0;
        display: flex;
        height: 10px;
        width: 100%;
        min-width: 320px;
        animation-name : stand_still;
    }
    .btnBox {
        background-color: transparent;
        border: none;
        outline-style: none;
        width: 100%;
        height: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }
    .triangleLeft {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid white;
        cursor: pointer;
        margin-bottom: 5px;
    }
    .triangleRight {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid white;
        cursor: pointer;
        margin-top: 5px;
    }
    .addUserBox {
        right: 5px;
    }
    .addUserBox button {
       font-size: 12px;
       height: 25px;
    }
    @keyframes move_up {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(-70px);
        
        }
    }
    @keyframes move_down {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(70px);
        
        }
    }
    @keyframes stand_still {
        from {        
        opacity: 1;     
        }   
        to {
        opacity: 1;
        transform: translateY(0px);
        
        }
    }
    @keyframes move_downInfo {

        from {
        margin-top: 53px;
        }
        to {
        margin-top:  113px;
        }   
    }
    @keyframes move_upInfo {
    
        from {
            margin-top: 113px;
        }
        to {
            margin-top: 53px;
        }   
    }

    @keyframes move_downSubheader {
        from {
            margin-top: 10px ;
        }
        to {
            margin-top: 80px ;
        }
    }
    @keyframes up_downSubheader {
        from {
            margin-top: 80px ;
        }
        to {
            margin-top: 10px ;
        }
    }
    
}
