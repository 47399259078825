.hostSearch {
  color: var(--superDarkGrey);
  font-size: 15px;
  font-weight: 500;
  position: relative;
}
.hostSearch input {
  box-sizing: border-box;
  border-radius: 100px;
  border: 1px solid var(--specialGrey);
  height: 26px;
  overflow: hidden;
  width: 100%;
  outline-style: none;
  margin-top: 8px;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 15px;
  text-indent: 5px;
}
.hostSearch input[data-invalid="true"] {
  border-color: red;
}
.hostSearch ul {
  width: 100%;
  position: absolute;
  top: 54px;
  display: flex;
  flex-direction: column;
  list-style: none;
  border-style: solid;
  border-width: 1px;
  border-color: var(--specialGrey);
  background-color: #fff;
  max-height: 20rem;
  overflow-y: scroll;
}
.hostSearch button {
  width: 100%;
  cursor: pointer;
  background-color: inherit;
  outline: none;
  border-style: dotted;
  border-color: var(--specialGrey);
  border-width: 1px;
  /* border-bottom-width: 2px; */
  padding-top: 5px;
  padding-bottom: 5px;
}
.hostSearch button:hover {
  background-color: var(--hoverColor);
}
.hostSearch button:last-child {
  border-bottom-width: 0px;
}