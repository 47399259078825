* {
    margin: 0;
    padding: 0;
}

.container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    width: 100%;
}

.searchBox {
    box-sizing: border-box;
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px;
    height: 700px;
    border-radius: 20px;
    box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, .08);
}

.tableContainer {
    width: calc(100% - 243px);
    padding-right: 20px;
    padding-left: 20px;
}

.numInputBoxHost {
    box-sizing: border-box;
    width: 100%;
    box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, .08);
    padding: 8px 0px;
    border-radius: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.filterBox {
    display: flex;
    flex-wrap: wrap;
    margin-left: 10px;
}

.selectMany {
    margin-left: 5px;
}

.inputBox {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    margin-right: 40px;
}
/* .rentalAddress {
    width: 100%;
} */
.rentalAddress label {
    color: #2e3032;
    font-size: 15px;
    font-weight: 500;
}
.rentalAddress input {
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid var(--specialGrey);
    height: 26px;
    overflow: hidden;
    width: 100%;
    outline-style: none;
    margin-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 15px;
    text-indent: 5px;
}

.selectBox {
    margin: 0 5px;
    width: 77px;
    border-radius: 10px;
    border: 2px solid #bdbdbd;
    outline-style: none;
    cursor: pointer;
    padding: 3px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
}

.selectBox option {
    font-size: 14px;
    font-weight: 500;
}

.radiusBoxHost {
    margin-top: 10px;
    width: 100%;
    height: 68vh;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, .08);
    overflow: scroll;
    scroll-behavior: smooth;
    overscroll-behavior: contain;
    background-color: rgb(240, 239, 239);
}

.tableHost {
    white-space: nowrap;
    margin: 0;
    border-collapse: separate;
    border-spacing: 0;
    table-layout: fixed;
    min-width: 2000px;
}

.tHead {
    width: 100%;
}

.tableHeader {
    width: 100%;
    height: 54px;

}

.tableHeader th {
    position: sticky;
    top: 0;
    z-index: 1;
    font-size: 16px;
    font-weight: 400;
    background-color: var(--superDarkGrey);
    text-align: left;
    padding: 0px 10px;
}

.theaderItemDropDown {
    width: 30px;
}

.theaderItemCheckBox {
    width: 40px;
}

.theaderItemCheckBox input {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 3px;
    border: 2px solid var(--specialGrey);
    outline-style: none;
    cursor: pointer;
}

.theaderItemCheckBox input:checked {
    background-color: var(--superDarkGrey);
}

.tdItemsCheckBox {
    width: 40px;
}

.tdItemsCheckBox input {
    -webkit-appearance: none;
    width: 15px;
    height: 15px;
    background-color: #fff;
    border-radius: 3px;
    border: 2px solid var(--specialGrey);
    outline-style: none;
    cursor: pointer;
}

.tdItemsCheckBox input:checked {
    background-color: var(--superDarkGrey);
}

.tableHeader th:first-child {
    border-radius: 20px 0px 0px 0px;
}

.tableHeader th:last-child {
    border-radius: 0px 5px 0px 0px;
}

.firstTh {
    background-color: #fff;
    width: 80px;
    text-align: center;
}

.center {
    position: sticky;
    left: 0px;
    top: 0px;
    height: 100%;
    text-align: center;
    border-right: 1px solid var(--lightGrey);
    z-index: 0;
    background-color: #fff;
}

.detailBtnBox {
    display: flex;
    width: 100%;
    justify-content: center;
    text-align: center;
}





.tdItems {
    font-size: 14px;
    height: 53px;
    text-align: left;
    border-bottom: 1px solid rgb(230, 230, 230);
    padding: 0px 10px;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    cursor: pointer;
}

.tdItems button {
    background-color: #fff;
}

.row {
    height: 53px;
}

.row:hover {
    background-color: rgb(235, 235, 235);
}

.inputShort {
    width: 70%;
    min-width: 156px;
    margin-bottom: 10px;
}

.inputLong {
    width: 100%;
    margin-bottom: 10px;
}

.removeMargin {
    margin-bottom: 0;
}

.btnBox {
    margin-top: 10px;
}

.passStatus {
    border-radius: 20px;
    padding: 5px 10%;
}

.labelBox {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    font-weight: 500;
}

.select {
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid var(--specialGrey);
    height: 30px;
    overflow: hidden;
    width: 100%;
    outline-style: none;
    margin-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    text-indent: 5px;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
    cursor: pointer;
}

.pageBox {
    box-sizing: border-box;
    width: 100%;
    background-color: #f8f8f8;
    display: flex;
    justify-content: space-between;
    padding: 12px 20px;
    border-radius: 0px 0px 20px 20px;
    box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, .08);
    min-width: 320px;
    margin-bottom: 20px;
}

.pageInput {
    margin: 0 5px;
    width: 42px;
    border-radius: 8px;
    border: 2px solid #bdbdbd;
    outline-style: none;
    cursor: pointer;
    padding: 1px 3px;
    -webkit-appearance: none;
    -moz-appearance: none;

}

.loading {
    position: absolute;
    margin-top: 10px;
    margin-left: 10px;
    font-weight: 600;
    color: var(--specialGrey);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loader {
    box-sizing: border-box;
    width: 100px;
    height: 20px;
    display: flex;
    justify-content: space-around;
    /* border: 1px solid grey; */
    position: relative;
}

.registrationStatus {
    border-radius: 5px;
    padding: 5px 10px;
}

.grey {
    background-color: #C4C4C4;
    color: white;
}

.green {
    background-color: #B4E7B6;
    color: #404640;
}

.pink {
    background-color: #EEB6B6;
    color: #770B0B;
}

.orange {
    background-color: #E9A41E;
    color: #784F00;
}

.red {
    background-color: #B90404;
    color: white;
}

.nameLength {
    width: 170px;
}

.phoneNumberLength {
    width: 150px;
}

.ownerpinLength {
    width: 120px;
}

.cityLength {
    width: 150px;
}

.statusLength {
    width: 170px;
}

.passNumberLength {
    width: 120px;
}

.approverLength {
    width: 170px;
}

.dateLength {
    width: 170px;
}

/* .loader::before {
       position: absolute;
       content: '';
       left: 20px;
       right: 20px;
       border-top: 2px solid blue;
       top: 50%;
       transform: translateY(-50%);
   } */
.loader>div {
    width: 20px;
    height: 20px;
    background-color: var(--mainColor);
    transform: rotate(45deg);
    animation: loader-1 600ms cubic-bezier(0.25, 0.46, 0.45, 0.94) infinite alternate;
}

div.two {
    animation-delay: 200ms;
}

div.three {
    animation-delay: 400ms;
}

@keyframes loader-1 {
    from {
        transform: rotate(45deg) scale(1);
    }

    to {
        transform: rotate(45deg) scale(0.8);
    }
}

.rcDownloaderBox {
    margin-right: 10px;
}

.rcDownloaderBox button {
    width: 67.33px;
    height: 26.39px;
    font-size: 16px;
    font-weight: 500;
}

.rightContent {
    display: flex;
    align-items: center;
}

.approved {
    color: rgb(70, 123, 254);
    cursor: pointer;
}

.approved:hover {
    background-color: var(--lightGrey);
    padding: 10px;
    border-radius: 4px;
}

.approverBox {
    display: flex;
    align-items: center;
}

.circle {
    width: 21px;
    height: 21px;
    background-color: var(--thirdColor);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: white;
}

.circleNone {
    width: 21px;
    height: 21px;
    background-color: var(--lightGrey);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 5px;
    color: white;
}

@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {
    .container {
        margin-top: 0px;
        width: 100%;
    }

    .radiusBoxHost {
        margin-right: 0px;
        width: 100%;
    }

    .numInputBoxHost {
        width: 100%;
    }


}

@media screen and (min-width:768px) and (max-width:991px) {
    .container {
        margin-top: 0px;
        width: 100%;
    }

    .radiusBoxHost {
        margin-right: 0px;
        width: 100%;
        height: 60vh;
    }

    .numInputBoxHost {
        width: 100%;
    }
}

@media screen and (max-width:767px) {
    .container {
        margin-top: 0px;
        width: 100%;
        flex-direction: column;
    }

    .searchBox {
        height: auto;
        padding-top: 15px;
        padding-bottom: 5px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;
        border-radius: 0px;
        min-width: 320px;
    }

    .inputShort {
        min-width: 156px;
        margin-bottom: 10px;
    }

    .inputLong {
        margin-bottom: 0px;
    }

    .btnBox {
        margin-top: 15px;
    }

    .radiusBoxHost {
        position: static;
        margin-left: 0px;
        margin-top: 10px;
        width: 100%;
        border-radius: 0;
        height: 40vh;
    }

    .tableHeader th:first-child {
        border-radius: 0px;
    }

    .tableHeader th:last-child {
        border-radius: 0px;
    }

    .tableHeader th:nth-child(5) {
        border-radius: 0px;
    }

    .tableHeader {
        height: 50px;
    }

    .tableHeader th {
        font-size: 14px;
    }

    .tdItems {
        font-size: 14px;
    }

    .tableContainer {
        width: 100%;
        padding-right: 0px;
        padding-left: 0px;
        margin-bottom: 40px;
    }

    .numInputBoxHost {
        position: static;
        min-width: 320px;
        border-radius: 0px;
        margin-bottom: 0px;
        width: 100%;
        margin-bottom: -10px;
    }

    .inputBox {
        font-size: 14px;
        margin-right: 20px;
    }

    .labelBox {
        font-size: 14px;
    }

    .select {
        height: 24px;
        margin-top: 5px;
        font-size: 14px;
    }

    .pageInput {
        margin: 0 5px;
        width: 30px;
        border-radius: 8px;
        border: 2px solid #bdbdbd;
        outline-style: none;
        cursor: pointer;
        padding: 0px 3px;
        -webkit-appearance: none;
        -moz-appearance: none;

    }

    .rcDownloaderBox {
        margin-right: 0px;
        margin-bottom: 10px;
        margin-right: 10px;
    }

    .rightContent {
        flex-direction: column;
        align-items: flex-end;
    }

    .inputBox {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        margin-right: 0px;
        min-width: 190px;
    }
    .rentalAddress label {
        color: #2e3032;
        font-size: 14px;
        font-weight: 500;
    }
    .rentalAddress input {
        margin-top: 5px;
    }

}