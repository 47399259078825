.nameAddressSearchBox {
  background-color: white;
  padding: 70px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.infoBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.titleBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.title {
  font-size: 24px;
  margin-bottom: 6px;
  color: var(--superDarkGrey);
}
.subTitle {
  color: var(--superDarkGrey);
  text-align: center;
}
.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}
.formInfor {
  width: 60%;
}
.inputRow {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
 }
 .inputLong {
  margin-bottom: 0px;
  width: 49%;  
 }
.inputLong input {
 height: 30px;
 }
 .genericSubmitBtn {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 60px;
}
.genericSubmitBtnInnerBox {
  width: 20%;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
  .nameAddressSearchBox {
    padding: 40px;
  }
  .formInfor {
    width: 80%;
  }
  .genericSubmitBtnInnerBox {
    width: 200px;
  }
}
@media screen and (max-width:767px) {
  .genericSubmitBtnInnerBox {
    width: 200px;
  }
  .nameAddressSearchBox {
    background-color: white;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }
  .formInfor {
    width: 100%;
  }
  .inputRow {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
   }
   .inputLong {
    margin-bottom: 8px;
    width: 100%;  
   }
}
