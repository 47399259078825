.form {
    width: 100%;
    display: flex;
    justify-content: center;
}
.infoBox {
    width: 40%;
    margin-top: 40px;
}
.label {
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 500;
    color: var(--superDarkGrey);
}
.input {
    box-sizing: border-box;
    border-radius: 100px;
    border: 1px solid var(--specialGrey);
    height: 26px;
    overflow: hidden;
    width: 100%;
    outline-style: none;
    margin-top: 8px;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 16px;
    text-indent: 5px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url(../../../assets/traingleDownSmall.svg) no-repeat center right;
}
.row {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
.inputShort {
    width: 48%;
}
.title {
    font-size: 20px;
    font-weight: 500;
}
.personalInfoBox {
    margin-top: 20px;
    margin-bottom: 20px;
}
.btnBox {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.btnCancel button {
    background-color: #fff;
    border: 1px solid var(--thirdColor);
    color: var(--thirdColor);
}
.btnCancel button:hover {
    border: none;
}
.btnView {
    margin-top: 10px;
}
.inputShort input {
    height: 30px;
    font-size: 16px;
}
.inputShort select {
    height: 30px;
}
@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .infoBox {
        width: 60%;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .infoBox {
        width: 60%;
    }
}
@media screen and (max-width:767px) {
    .infoBox {
        width: 90%;
        margin-top: 20px;
    }
    .note {
        font-size: 14px;
    }
}