* {
  margin: 0;
  padding: 0;
}
.container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  min-width: 320px;
}
.box {
  width: 82%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 200px;
  border-radius: 20px;
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.25);
  padding-bottom: 40px;
}
.subHeader {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: 103px;
  border-radius: 20px 20px 0 0;
}
.circle {
  width: 20px;
  height: 20px;
  border-radius: 20px;
  margin-left: 45px;
  margin-right: 17px;
}
.subHeaderTitle {
  font-size: 35px;
  font-weight: 500;
}
.backBtn {
  width: 141px;
  position: absolute;
  right: 45px;
}
@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .subHeader {
    border-radius: 0;
  }
  .box {
    width: 100%;
    display: flex;
    margin-top: 155px;
    border-radius: 0px;
  }
}
@media screen and (max-width: 767px) {
  .circle {
    width: 13px;
    height: 13px;
    margin-left: 10px;
    margin-right: 5px;
  }
  .subHeaderTitle {
    font-size: 20px;
    font-weight: 500;
  }
  .subHeader {
    border-radius: 0;
  }
  .box {
    width: 100%;
    display: flex;
    margin-top: 20px;
    border-radius: 0px;
  }
  .box {
    width: 100%;
    display: flex;
    margin-top: 44px;
    border-radius: 0px;
    flex-direction: column;
  }
  .backBtn {
    position: absolute;
    width: 70px;
    right: 10px;
  }
}
