* {
    margin: 0;
    padding: 0;
   }
   .rentalUnitTableData {
        position: relative;
   }
   .theaderItemDropDown {
       width: 30px;
   }
   .tdItemsDropDown{
       width: 30px;
       border-bottom: 1px solid rgb(230, 230, 230);
       /* background: url(../../../assets/traingleDropDownMenu.svg) no-repeat scroll right center transparent; */
       cursor: pointer;
   }
   .active a {
    color: var(--thirdColor);
   }
   .active .tabLine {
       background-color: var(--thirdColor);
   }

   .container {
    position: relative;
    display: flex;
    justify-content: flex-start;
    margin-top: 0px;
    width: 100%;
   }
   .flexBox {
      width: 100%;
      padding-right: 20px;
   }
   .infoBox {
       width: 100%;
       box-sizing: border-box;
       position: relative;
       border-radius: 20px;
       padding-top: 30px;
       padding-bottom: 30px;
       display: flex;
       flex-direction: column;
       align-items: center;
       box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
       min-width: 320px;
   }
   .infoTitle {
       margin: 0px;
       font-size: 30px;
       font-weight: 500;
   }
   .exitBtn {
       position: absolute;
       right: 10px;
       top: 10px;
       background: url(../../../assets/blackExit.svg) no-repeat center;
       width: 22px;
       height: 22px;
       border: none;
       outline-style: none;
       cursor: pointer;
   }
   .infoSectionBox {
       display: flex;
       margin-top: 20px;
       justify-content: space-evenly;
       width: 100%;
       flex-wrap: wrap;
   }
   .tabBox {
    box-sizing: border-box;
    margin-top: 20px;
    height: 80px;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    border-radius: 20px 20px 0px 0px ;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    min-width: 320px;
}
.tab {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex: 1;
    font-size: 18px;
    font-weight: 500;
}
   .tabTitle {
       color: var(--darkGrey);
       /* margin-bottom: 20px; */
   }
   .tabTitleClicked {
    color: var(--thirdColor);
    margin-bottom: 20px;
   }
   
   .tabLine {
       position: absolute;
       bottom: 10px;
       background-color: var(--lightGrey);
       height: 10px;
       width: 100%;
   }


   .section {
       width: 386px;
       margin: 10px 10px;
   }
   .sectionHeader {
    padding: 10px 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
   }
   .sectionHeaderTitle {
    font-size: 14px;
    font-weight: 500;
   }
   .statusSelect {
       -webkit-appearance:none;
       background: url(../../../assets/selectDown.svg) no-repeat right center;
       border: none;
       width: 161px;
       height: 30px;
       background-color: rgb(235, 235, 235);
       border-radius: 5px;
       outline-style: none;
       cursor: pointer;
       padding-left: 5px;
       margin-top: 10px;
       font-size: 16px;
   }

   .listItemBox {
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    padding: 10px 0px;
  }
  .listTitle {
      
      justify-content: flex-start;
      text-align: start;
  }
  .listContent {
      list-style: none;
      font-weight: 500;
  }
  .circle {
      width: 15px;
      height: 15px;
      border-radius: 20px;
      margin-right: 10px;
  }
  .listItemBoxEdu {
    width: 100%;
    flex: 1;
    display: flex;
    justify-content: space-between;
    padding: 5px 0px;
  }
  .tablesBox {
      margin-bottom: 20px;
  }

  .guestHidden {
    display: none;
    flex: 0;
}
.vendorHidden {
    display: none;
    flex: 0;
}
.residentHidden {
    display: none;
    flex: 0;
}
.passStatus {
    border-radius: 5px;
    padding: 5px 10px;
}
.grey {
    background-color: #C4C4C4;
    color: white;
}
.green {
    background-color: #B4E7B6;
    color: #404640;
}
.pink {
    background-color: #EEB6B6;
    color: #770B0B;
}
.orange {
    background-color: #E9A41E;
    color: #784F00;
}
.red {
    background-color: #B90404;
    color: white;
}

/* .active {
    background-color: #B4E7B6;
    color: #404640;
} */
.actionBox {
    position: absolute;
    top: 40px;
    right: 10px;
    z-index: 1;
    width: 123px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0px 2px 5px 3px rgba(0,0,0,.08);
    border: 1px solid var(--specialGrey);
}
.actionBtn {
    height: 40px;
    width: 123px;
    background-color: #fff;
    border: none;
    border-bottom: 1px dashed var(--lightGrey);
    cursor: pointer;
    font-weight: 500;
    outline-style: none;
}
.actionBtn:hover {
    color: white;
    background-color: var(--mainColor);
}
.tableRadiusBox {
    overflow-x: scroll;
    overflow-y: scroll ;
    height: 200px;
}
.vehicleHidden {
    display: none;
}
.complaintHidden {
    display: none;
}
.employeeHidden {
    display: none;
}
.alignBox {
    display: flex;
    align-items: center;
}
.statusBox {
    display: flex;
    align-items: center;
}
.checkBox {
    margin-right: 10px;
    width: 16px;
    height:16px;
    appearance: none;
    background: url(../../../assets/checkBox\ .svg) no-repeat center;
    cursor: pointer;
    border: 2px solid var(--lightGrey);
}
.checkBox:checked{
    background: url(../../../assets/checked.svg ) no-repeat center;
    background-color: #fff;
}
.passStatusSelection {
    text-align: center;
    padding: 5px 5px;
    padding-left: 0px;
    background-color: #777777;
    border-radius: 10px;
    color: #fff;
    cursor: pointer;
    outline-style: none;
}



  @media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
    .container {
        margin-top: 0px;
        width: 100%;
       }
    .tab {
        font-size: 18px;
        font-weight: 500;
    }
}
@media screen and (min-width:768px) and (max-width:991px) {
    .container {
        margin-top: 0px;
        width: 100%;
       }
       .flexBox {
        width: 100%;
        padding-right: 20px;
     }
     .tab {
        font-size: 16px;
        font-weight: 500;
    }
}
@media screen and (max-width:767px) {
    .infoTitle {
        font-size: 20px;
    }
    .container {
        box-sizing: border-box;
        margin-top: 0px;
        width: 100%;
       }
       .infoBox {
        box-sizing: border-box;
        position: relative;
        border-radius: 0px;
        padding: 30px 5px;
    }
    .listTitle {
      text-align: left;
      font-size: 14px;
    }
    .listContent {
      font-size: 14px;
    }
    .tab {
        flex-direction: column;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: 500;
    }
    .tabTitle {
        color: var(--darkGrey);
        margin-bottom: 20px;
        margin-top: 20px;
        word-wrap: break-word;
        word-break: break-all;
    }
    .tabBox {
      border-radius: 0px;
    }
    .flexBox {
        padding-right: 0px;
     }
     .exitBtn {
        width: 9px;
    }
    .statusSelect {
        font-size: 14px;
    }
   
}