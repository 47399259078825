.hidden {
  display: none;
}
.box {
  box-sizing: border-box;
}
.scanPassBox {
  background-color: white;
  padding: 70px;
  display: flex;
  box-sizing: border-box;
  /* justify-content: center; */
}
.qrCodeBox {
  flex: .4;
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.enterNumBox {
  flex: .6;
  display: flex;
  margin-left: 20px;
}
.qrCodeBackground {
  width: 200px;
  background-color: rgb(228, 228, 228);
  padding: 20px 40px;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.qrCodeTitle {
  font-weight: 700;
  color: var(--superDarkGrey);
  font-size: 24px;
  margin: 10px 0px 20px 0px;
}
.enterNumTitle {
  font-size:24px ;
  font-weight: 500;
  color:var(--superDarkGrey);
  margin-bottom: 10px;
}
.enterNumSubTitle {
  color: var(--superDarkGrey);
  font-weight: 500;
  margin-bottom: 40px;
}
.form .inputLong label {
  font-size: 18px;
}
.form .inputLong input {
  height: 38px;
}
.genericSubmitBtn {
  display: flex;
  width: 100%;
  margin-top: 40px;
}
.genericSubmitBtnInnerBox {
  width: 50%;
}
.passBox {
  background-color: white;
  padding: 70px;
  display: flex;
}
.passInfo,
.passAction {
  width: 50%;
  box-sizing: border-box;
}

.passAction {
  display: flex;
  justify-content: center;
  margin-left: 40px;
}

.passField {
  margin: 20px 0;
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.passFieldCost {
  margin: 20px 0;
  display: flex;
  align-items: center;
  margin-bottom: 38px;
}
.passAttr {
  font-size: 18px;
  font-weight: 500;
  color: var(--superDarkGrey);
}

.passAttr,
.passValue {
  width: 50%;
}
.incomplete {
  width: 171px;
  height: 39px;
  background-color: #c4c4c4;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
}
.active {
  width: 171px;
  height: 39px;
  background-color: var( --green);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
}
.expired {
  width: 171px;
  height: 39px;
  background-color: var( --red);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
}
.inactive {
  width: 171px;
  height: 39px;
  background-color: #9CCC8B;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 500;
}
.unpaid {
  width: 123px;
  height: 40px;
  border: 2px solid var(--red);
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--red);
  background: url(../../../assets/unpaid.svg) no-repeat left;
  background-color: #fad8d8;
  text-indent: 14px;
}
.paid {
  width: 123px;
  height: 40px;
  border: 2px solid #28AE2D;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #28AE2D;
  background: url(../../../assets/paid.svg) no-repeat left;
  background-color: #E2EFE2;
  text-indent: 14px;
}
.refunded {
  width: 140px;
  height: 40px;
  border: 2px solid #E9A41E;;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ac750e;
  background: url(../../../assets/refunded.svg) no-repeat left;
  background-color: #f8e5d4;
  text-indent: 14px;
}
.noCharge {
  width: 148px;
  height: 40px;
  border: 2px solid #979797;
  border-radius: 10px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #979797;
  background: url(../../../assets/noCharge.svg) no-repeat left;
  background-color: #e1e1e1;
  text-indent: 14px;
}
.passTextValue {
  font-weight: 600;
  font-size: 18px;
}
.paymentInfo {
  display: flex;
  align-items: center;
}
.paymentTitle {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 500;
}
.alert {
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #d41111;
  border-radius: 10px;
  margin-top: 10px;
  margin-bottom: 40px;
}
.alert a {

  color: #d41111;

}
.btnBox {
  margin: 20px 0px;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
}
.btnBox button {
  width: 254px;
}
.inputClose {
  margin-top: 40px;
}
.inputClose input  {
 height: 40px;
 border-radius: 10px;
 font-size: 18px;
}
.passAttr {
  width: 35%;
}
.loading {
  text-align: center;
  height: 40px;
  padding: 20px 0;
  display: flex;
  justify-content: center;
}
.actionInner {
  width: 80%;
}
.scanButtonContainer {
  border: none;
  background-color: rgb(228, 228, 228);
}
.imageTicket {
  width: 392px;
  border: 1px solid rgb(226, 224, 224);
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-left: 45px;
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, .3));
}
.passInfo {
  display: flex;
  justify-content: center;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {
  .actionInner {
    width: 100%;
  }
}
@media screen and (min-width:768px) and (max-width:991px) {
  .scanPassBox {
    background-color: white;
    padding: 30px;
    display: flex;
    /* justify-content: center; */
  }
  .scanPassBox {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .enterNumBox {
    display: flex;
    margin-top: 40px;
    margin-left: 0;
  }
  .genericSubmitBtn {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .genericSubmitBtnInnerBox {
    width: 200px;
  }
  .passBox {
    flex-direction: column;
    padding: 10px;
  }
  .passInfo,
  .passAction {
    width: 100%;
  }
  .passAction {
    display: flex;
    justify-content: center;
    margin-left: 0px;
    margin-top: 40px;
  }
  .actionInner {
    width: 60%;
  }
  .passInfo {
    display: flex;
    justify-content: center;
  }
  .imageTicket {
    width: 375px;
    margin-left: 0px;
  }
  
}
@media screen and (max-width:767px) {
  .scanPassBox {
    background-color: white;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
  }
  .enterNumBox {
    display: flex;
    margin-top: 40px;
    margin-left: 0;
  }
  .genericSubmitBtn {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .genericSubmitBtnInnerBox {
    width: 200px;
  }
  .passBox {
    flex-direction: column;
    padding: 10px;
  }
  .passInfo,
  .passAction {
    width: 100%;
  }
  .passAction {
    display: flex;
    justify-content: center;
    margin-left: 0px;
  }
  .actionInner {
    width: 100%;
  }
  .passAttr {
    font-size: 16px;
  }
  .incomplete {
    width: 150px;
    height: 36px;
    background-color: #c4c4c4;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .active {
    width: 150px;
    height: 36px;
    background-color: var( --green);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .expired {
    width: 150px;
    height: 36px;
    background-color: var( --red);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .inactive {
    width: 150px;
    height: 36px;
    background-color: #9CCC8B;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 16px;
    font-weight: 500;
  }
  .passInfo {
    display: flex;
    justify-content: center;
  }
  .imageTicket {
    width: 375px;
    margin-left: 0px;
  }

}
