.inputBox {
  width: 100%;
}
.standardTitle {
  font-size: 18px;
}
.standardInput {
  width: 100% !important;
  border: none !important;
  border-bottom: 2px solid var(--lightGrey) !important;
  border-radius: 0px !important;
  font-size: 16px !important;
  height: 34px !important;
}
.standardInput:focus {
  border-bottom: 2px solid var(--mainColor) !important;
  color: var(--mainColor);
}
.smallStandardTitle {
  font-size: 17px;
}
.smallStandardInput {
  width: 100% !important;
  border: none !important;
  border-bottom: 2px solid var(--lightGrey) !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  height: 25px !important;
}
.smallStandardInput:focus {
  border-bottom: 2px solid var(--mainColor) !important;
  color: var(--mainColor);
}
.materialTitle {
  font-size: 12px;
}
.materialInput {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid var(--lightGrey) !important;
  border-radius: 0px !important;
  font-size: 14px !important;
  height: 25px !important;
}
.countryBtn {
  border-radius: none !important;
  border: var(--lightGrey) !important;
  border: 2px solid transparent !important;
  background-color: transparent !important;
}
.closedCountryBtn {
  border-radius: none !important;
  border: var(--lightGrey) !important;
  border: 2px solid transparent !important;
  background-color: transparent !important;
  margin-left: 8px !important;
}
.closedTitle {
  font-size: 14px !important;
  color: var(--darkGrey);
  font-weight: 500;
  margin-bottom: 8px;
}
.closedInput {
  box-sizing: border-box !important;
  border-radius: 100px !important;
  border: 1px solid var(--specialGrey) !important;
  height: 34px !important;
  overflow: hidden !important;
  width: 100% !important;
  outline-style: none !important;
  font-size: 16px !important;
  padding-left: 50px !important;
  color: var(--superDarkGrey);
}

@media screen and (min-width: 1200px) {
}
@media screen and (min-width: 992px) and (max-width: 1199px) {
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .standardTitle {
    font-size: 16px;
  }
  .standardInput {
    height: 30px !important;
    font-size: 14px !important;
  }
}
@media screen and (max-width: 767px) {
  .standardTitle {
    font-size: 16px;
  }
  .standardInput {
    height: 30px !important;
    font-size: 14px !important;
  }
  .smallStandardTitle {
    font-size: 14px;
  }
  .closedInput {
    height: 28px !important;
    font-size: 14px !important;
    padding-left: 50px !important;
  }
}
