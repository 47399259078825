.box{
    padding: 20px;
}
.passInfo{
    margin: 20px;
}
.passInfos{
  margin: 20px;
  display: flex
}
.passNumber{
    display: flex;
    justify-content: center;
    font-size: 22px;
    margin: 10px;
}
.subHeading{
  margin-bottom: 8px;
}
.actionBtn {
    border: none;
    color: var(--mainColor);
    background-color: transparent;
    font-weight: 500;
    cursor: pointer;
    display: flex;
   }
   .actionBox {
     width: 100%;
     height: 100%;
     display: flex;
     align-items: center;
   }
   .imgBox{
    display: flex;
    margin: 20px;
   }
   .actionBtn svg {
     margin-top: 1px;
     min-width: 14px;
     stroke: var(--mainColor);
   }
  @media screen and (min-width: 1200px) {
  }
  @media screen and (min-width: 992px) and (max-width: 1199px) {
   
  }
  @media screen and (min-width: 768px) and (max-width: 991px) {
 
  }
  @media screen and (max-width: 767px) {
  
  }
  