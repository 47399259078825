body.isleworth {
  --mainColor: #8C734B;
  --secondaryColor: #8C734B;
  --thirdColor: #595042;
  --hoverColor: #262219;
  --superDarkGrey: #2e3032;
  --darkGrey: #636365;
  --mediumGrey: #75787c;
  --lightGrey: #d8d8d8;
  --specialGrey: #bdbdbd;
  --sucessColor: #28ae2d;
  --alertColor: #ca2a06;
  --backgroungColor: #f2f2f2;
  --green: #28ae2d;
  --red: #ca2a06;
  --gradient: linear-gradient(#F2E5D0, #F2E5D0);
}
