.container {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: 20px;
  padding: 0px 10px;
}

.tableBox {
  width: 100%;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, .08);
  overflow-x: auto;
}

.tHead {
  width: 100%;
}

.tableHeader {
  width: 100%;
  height: 54px;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--superDarkGrey);
  color: white;
}

.tableHeader th {
  font-weight: 500;
  min-width: 50px;
  padding: 10px;
  background-color: var(--superDarkGrey);
  text-align: left;
}

.table td {
  text-align: left;
  padding: 10px;
}

.table {
  box-sizing: border-box;

}

.table tbody tr {
  height: 53px;
  background-color: #fff;
}

.table tbody tr td {
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid rgb(230, 230, 230);
  overflow: hidden;
  text-overflow: ellipsis;
}

.passStatusActive {
  border-radius: 20px;
  padding: 5px 15.66px;
  background-color: var(--green);
}

.passStatusExpired {
  border-radius: 20px;
  padding: 5px 11.115px;
  background-color: var(--red);
}

.passStatusInactive {
  border-radius: 20px;
  padding: 5px 10px;
  background-color: var(--lightGrey);
}

.pageBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.pageInput {
  margin: 0 5px;
  width: 42px;
  border-radius: 8px;
  border: 2px solid #bdbdbd;
  outline-style: none;
  cursor: pointer;
  padding: 1px 3px;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.viewInReport__button {
  background-color: #fff;
  border: 1px solid #898E93;
  border-radius: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 5px;
  padding-right: 5px;
  cursor: pointer;
}

.lastTh {
  width: 160px;
}
.table {
  width: 100%;
  min-width: 1300px;
}


@media screen and (min-width:1200px) {}

@media screen and (min-width:992px) and (max-width:1199px) {
  .table tbody tr td {
    font-size: 14px;
  }

  .tableHeader th {
    font-size: 14px;
    font-weight: 500;
    min-width: 100px;
  }

}

@media screen and (min-width:768px) and (max-width:991px) {
  .table {
    width: 1300px;
  }

  .table tbody tr td {
    font-size: 14px;
  }

  .tableHeader th {
    font-size: 14px;
    font-weight: 500;
    min-width: 100px;
  }
}

@media screen and (max-width:767px) {
  .refundTitle {
    font-size: 16px;
  }

  .table {
    width: 1200px;
    border-radius: 0px;
  }

  .table tbody tr td {
    font-size: 14px;
  }

  .tableHeader th {
    font-size: 14px;
    font-weight: 500;
    min-width: 100px;
  }

  .tableHeader {
    height: 50px;
  }

  .pageBtn button {
    width: 100px;
  }
}