* {
  margin: 0;
  padding: 0;
}

.container {
  position: relative;
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-direction: column;
  background-color: #fff;
}

.overview {
  display: flex;
  align-items: center;
  padding: 12px 10px;
}

.overViewTitle {
  font-size: 18px;
  margin-right: 10px;
}

.box_overview {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
  box-shadow: 0px 1px 2px 3px rgba(0, 0, 0, 0.08);
  border-radius: 10px;
  cursor: pointer;
  height: 38px;
  width: 200px;
  margin: 0px 10px;
}
.box_search {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 5px 10px;
}
.searchInput {
  height: 30px;
  padding-right: 32px;
  border: none;
  border: 1px solid #BBBBC5;
  border-radius: 5px;
  background: url(../../../assets/searchIcon.svg) no-repeat right;
  background-color: #f2f2f2;
  padding-left: 5px;
  outline-style: none;
  margin-right: 10px;
}
.selectMany {
  margin: 0px 5px;
}

.radiusBoxSecurity {
  margin-top: 10px;
  width: 100%;
  height: 68vh;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, .08);
  overflow: scroll;
  scroll-behavior: smooth;
  /* overscroll-behavior: contain; */
  background-color: rgb(240, 239, 239);
}

.tHead {
  width: 100%;
}

.tableHeader {
  width: 100%;
  height: 54px;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--superDarkGrey);
  color: white;
}

.tableHeader th {
  font-weight: 400;
  min-width: 100px;
  padding: 0px 10px;
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--superDarkGrey);
}

.tableHeader th:first-child {
  width: 80px;
}
.firstTh {
  background-color: #fff;
  width: 80px;
  text-align: center;
}

.tableSecurity td {
  text-align: left;
}

.tableSecurity {
  box-sizing: border-box;
  text-align: left;
  min-width: 1800px;
}

.tableSecurity tbody tr {
  height: 53px;
  background-color: #fff;
}

.tableSecurity tbody tr:hover {
  background-color: rgb(235, 235, 235);
}

.tableSecurity tbody tr td {
  font-size: 14px;
  text-align: left;
  border-bottom: 1px solid rgb(230, 230, 230);
  padding: 0px 10px;
  height: 53px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;

}

.pageBox {
  box-sizing: border-box;
  width: 100%;
  background-color: #f8f8f8;
  display: flex;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 0px 0px 20px 20px;
  box-shadow: 0px 2px 5px 3px rgba(0, 0, 0, .08);
  min-width: 320px;
  margin-bottom: 20px;
}

.pageInput {
  margin: 0 5px;
  width: 42px;
  border-radius: 8px;
  border: 2px solid #bdbdbd;
  outline-style: none;
  cursor: pointer;
  padding: 1px 3px;
  -webkit-appearance: none;
  -moz-appearance: none;

}
.center {
  position: sticky;
  left: 0px;
  top: 0px;
  height: 100%;
  text-align: center;
  border-right: 1px solid var(--lightGrey);
  z-index: 1;
  background-color: #fff;
}

.detailBtnBox {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
}
.detailBtnBox  button {
  background-color: #fff;
}
.secondTh {
  width: 60px;
}
.idx1 {
  z-index: 2;
}

@media screen and (min-width:1200px) {
    
}
@media screen and (min-width:992px) and (max-width:1199px) {

}
@media screen and (min-width:768px) and (max-width:991px) {
  
  .box_overview {
    margin-left: 0px;
    margin-bottom: 8px;
  }
  .box_search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;
  }
}
@media screen and (max-width:767px) {
  .overview {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 12px 10px;
  }
  .overViewTitle {
    font-size: 18px;
    margin-right: 10px;
    margin-bottom: 10px;
  }
  
  .box_overview {
    margin-left: 0px;
    margin-bottom: 8px;
  }
  .box_search {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 5px 10px;
  }
}
